import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  a {
    text-decoration: none;
    color: #34495e;
    font-weight: 500;
  }
  .hide-mobile {
    @media (max-width: 768px) {
      display: none;
    }
  }
  .show-mobile {
    @media (min-width: 768px) {
      display: none;
    }
  }
  .center {
    text-align: center;
  }
`;

export const TableHeading = styled.div`
  padding: 16px;
  border-bottom: 1px solid #dadada;
`;

export const TableHeader = styled.thead`
  margin: 10px;
  font-weight: 500;
  font-size: 18px;
`;

export const TableBody = styled.tbody`
  padding: 5px;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
`;

export type TableTrProps = { error?: boolean; lastRow?: boolean };
export const TableTr = styled.tr`
  border-bottom: 1px solid #f2f2f2;
  height: 40px;
  margin: 10px;
  background-color: ${(props: TableTrProps) =>
    props.error === undefined
      ? "#ffffff"
      : props.error === false
      ? "#ffffff"
      : "#f9f9f9"};
  color: ${(props: TableTrProps) =>
    props.error === undefined
      ? "#000000"
      : props.error === false
      ? "#000000"
      : "#e62739"};
  ${(props: TableTrProps) => props.lastRow && `border-top: 2px solid #000`}
`;

export const TableTh = styled.th`
  text-align: left;
  font-weight: 300;
  padding: 10px;
`;
