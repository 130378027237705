import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { clearSession } from "../../store/system";
import * as AuthService from "../../services/AuthService";
import { LoadingPage } from "../layout/Loading";
import styled, { keyframes } from "styled-components";
import { BasicCard } from "../cards/BasicCard";
import { useHistory, useLocation } from "react-router-dom";
import { BasicButton } from "../layout/Buttons";
import logo from "../../../assets/nytt-logo2.png";
import { LoginErrorModal } from "../modals/LoginErrorModal";

import { Spinner } from "@styled-icons/evil";
interface MobileLoginSuccessResponse {
  status: "success";
  token: string;
  code: string;
}

interface MobileLoginErrorResponse {
  status: "error";
  message: string;
}

type MobileLoginResponse =
  | MobileLoginSuccessResponse
  | MobileLoginErrorResponse;

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  position: relative;
  margin: auto;
  max-width: 344px;
  text-align: center;
  border: 1px solid #ccdfff;
  border-radius: 8px;
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;

  img {
    position: absolute;
    top: -54px;
    left: calc(50% - 70px);
    padding: 15px;
    max-width: 100%;
    background-color: #ffffff;
    height: 48px;
  }
`;

const CardContent = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  button + button {
    border-left: solid 1px white;
  }
`;

const Logo = styled.img`
  height: 50px;
  margin-right: 10px;
`;

type H3Props = { ok?: boolean };
const H3 = styled.h3`
  color: ${(props: H3Props) => (props.ok ? "#72b08e" : "#000000")};
`;

const Form = styled.form`
  text-align: left;
  padding: 25px;
`;

type FormGroupProps = { valid?: boolean; noValidation?: boolean };
const FormGroup = styled.div`
  position: relative;
  margin-top: 35px;
  label {
    top: 100%;
    margin-top: -64px;
    margin-left: 8px;
    height: 30px;
    color: #015196;
  }
  color: ${(props: FormGroupProps) =>
    props.noValidation ? "gray" : props.valid ? "green" : "red"};
`;
const FormLabel = styled.label`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  color: gray;
`;

const FormInputContainer = styled.div`
  padding: 0px 4px 4px 0px;
  border: 1px solid #ccdfff;
  border-radius: 6px;
`;
const FormInput = styled.input`
  height: 40px;
  width: 90%;
  display: block;
  border: none;
  border-radius: 6px;
  padding-top: 24px;
  padding-left: 12px;
  background: #fff;
  font-size: 22px;
  :focus {
    outline: none;
  }
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  `;

const SpinningIcon = styled(Spinner as any)`
  animation: 2s linear ${spin} infinite;
`;

const LoginMessaging = styled.div`
  margin-top: 16px;
  font-size: 12px;
  max-width: 294px;
`;

type LoginErrorPageProps = {
  title: string;
  message: string;
};

const LoginErrorPage = (props: LoginErrorPageProps) => {
  let history = useHistory();
  return (
    <div>
      <LoginWrapper>
        <BasicCard>
          <CardContent>
            <H3>{props.title}</H3>
            <p>{props.message}</p>
            <BasicButton
              fluid
              buttonType="primary"
              onClick={() => {
                history.push("/");
              }}
            >
              Til baka
            </BasicButton>
          </CardContent>
        </BasicCard>
      </LoginWrapper>
    </div>
  );
};

/**
 * Parse phone number and return cleaned phone number and error message if any
 * @param phone - phone number to parse
 * @returns object with cleaned phone number and error message if any
 */
const parsePhone = (
  phone: string
): { isValid: boolean; error?: string; cleanedPhone: string } => {
  // Remove any non-numeric characters (e.g., spaces, dashes, parentheses)
  const cleanedPhone = phone.replace(/\D/g, "");

  // Check if the cleaned phone number has exactly 7 digits
  if (cleanedPhone.length !== 7) {
    return {
      isValid: false,
      cleanedPhone,
      error: "Phone number must be exactly 7 digits.",
    };
  }

  // If we get here, the phone number is valid
  return {
    isValid: true,
    cleanedPhone,
  };
};

/**
 * Format phone number to 000-0000 format
 * @param value - phone number to format
 * @returns formatted phone number
 */
const formatPhoneNumber = (value: string) => {
  if (!value) return "";

  const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
  const part1 = numericValue.slice(0, 3);
  const part2 = numericValue.slice(3, 7);

  if (part2.length > 0) {
    return `${part1}-${part2}`;
  }

  return part1;
};

const LoginPage = () => {
  const dispatch = useDispatch();
  let location = useLocation();

  // Local state variables
  const { loggedIn, loggingIn } = useSelector(
    (state: AppState) => state.system
  );
  const [mobileLoginInProgress, setMobileLoginInProgress] = React.useState<
    boolean
  >(false);
  const [loginResponse, setLoginResponse] = React.useState<
    MobileLoginResponse
  >();
  const [loginError, setLoginError] = React.useState<{
    title: string;
    message: string;
  }>();
  const [phone, setPhone] = React.useState<string>("");

  // Alias for the error page URL
  const errorUrl = location.pathname === "/login/error";

  // handleError is a helper function to set the login error state and reset the login state
  const handleError = (title: string, message: string) => {
    setLoginError({ title, message });
    setMobileLoginInProgress(false);
    setLoginResponse(undefined);
  };

  return (
    <>
      {loggingIn ? (
        <LoadingPage />
      ) : loggedIn ? (
        <LoginWrapper>
          <Logo src={logo} alt="Heim" />
          <H3 ok>Þú ert innskráð(ur)!</H3>
          <p>Farðu á forsíðu til að byrja, eða skráðu þig út.</p>
          <FormGroup noValidation>
            <ButtonGroup>
              <BasicButton
                buttonType="secondary"
                fluid
                onClick={() => {
                  AuthService.logout().then(() => {
                    dispatch(clearSession());
                  });
                }}
              >
                Skrá mig út.
              </BasicButton>
            </ButtonGroup>
          </FormGroup>
          <p>
            eða fara á <a href="/">forsíðu</a>
          </p>
        </LoginWrapper>
      ) : errorUrl ? (
        <LoginErrorPage
          title="Villa kom upp í auðkenningu."
          message="Því miður þá kom upp villa í auðkenningar ferlinu. Vinsamlegast reyndu aftur síðar."
        />
      ) : (
        <>
          <LoginErrorModal
            open={!!loginError}
            title={loginError?.title || ""}
            message={loginError?.message || ""}
            onDone={() => setLoginError(undefined)}
          ></LoginErrorModal>
          <LoginWrapper>
            <Logo src={logo} alt="Heim" />

            <H3>Innskráning á mínar síður</H3>

            <p>
              Til að halda áfram þarftu að auðkenna þig með rafrænum skilríkjum.
            </p>

            <Form>
              <FormGroup valid={parsePhone(phone).isValid}>
                <FormInputContainer>
                  <FormInput
                    tabIndex={1}
                    disabled={mobileLoginInProgress}
                    type="tel"
                    required
                    id="phone"
                    placeholder="000-0000"
                    value={formatPhoneNumber(phone)}
                    onChange={(e) => {
                      const numericValue = e.currentTarget.value
                        .replace(/\D/g, "")
                        .slice(0, 7); // Remove non-numeric characters
                      setPhone(numericValue);
                    }}
                  />
                  <FormLabel htmlFor="phone">Símanúmer</FormLabel>
                </FormInputContainer>
              </FormGroup>
              <FormGroup noValidation>
                <BasicButton
                  tabIndex={1}
                  buttonType="primary"
                  fluid
                  disabled={!parsePhone(phone).isValid || mobileLoginInProgress}
                  onClick={async (e) => {
                    e.preventDefault();
                    setMobileLoginInProgress(true);

                    try {
                      // Initiate login
                      const response = await AuthService.login(
                        parsePhone(phone).cleanedPhone
                      );
                      const { cleanedPhone } = parsePhone(phone);
                      const {
                        status,
                        token,
                      } = response.data as MobileLoginSuccessResponse;

                      if (status === "success") {
                        setLoginResponse(response.data as MobileLoginResponse);

                        const pollStatus = async () => {
                          const statusResponse = await AuthService.status(
                            token,
                            cleanedPhone
                          );

                          switch (statusResponse.data.status) {
                            case "waiting":
                              // Continue polling when in "waiting" status
                              setTimeout(pollStatus, 3000);
                              break;

                            case "success":
                              // The user is now authenticated
                              // Full Refresh the page to get the new session
                              // As we get a response cookie from the endpoint.
                              window.location.replace("/");
                              break;

                            case "error":
                              // Some error occurred, most likely the user waited too long or wrong pin
                              handleError(
                                "Villa kom upp í auðkenningu.",
                                "Notandi hætti við auðkenningu, vinsamlegast reyndu aftur."
                              );
                              break;

                            default:
                              handleError(
                                "Villa kom upp í auðkenningu.",
                                "Eitthvað fór úrskeiðis við auðkenningu, vinsamlegast reyndu aftur."
                              );
                              break;
                          }
                        };

                        // Start polling
                        pollStatus();
                      } else {
                        handleError(
                          "Villa kom upp í auðkenningu.",
                          "Ekki tókst að auðkenna notanda, vinsamlegast reyndu aftur."
                        );
                      }
                    } catch (error) {
                      handleError(
                        "Villa kom upp í auðkenningu.",
                        "Ekki tókst að auðkenna notanda, vinsamlegast reyndu aftur."
                      );
                    }
                  }}
                >
                  {mobileLoginInProgress ? (
                    <SpinningIcon width="24px" />
                  ) : (
                    "Auðkenna"
                  )}
                </BasicButton>
              </FormGroup>
            </Form>

            <LoginMessaging>
              {mobileLoginInProgress &&
                loginResponse &&
                loginResponse.status === "success" && (
                  <p>
                    Öryggistala: {loginResponse.code} (þetta er ekki
                    pin-númerið). Staðfestu aðeins innskráningu ef sama
                    öryggistala birtist í símanum þínum.
                  </p>
                )}
            </LoginMessaging>
          </LoginWrapper>
        </>
      )}
    </>
  );
};

export default LoginPage;
