import { action } from "typesafe-actions";
import { SystemActionTypes, SystemState } from "./types";
import { Flag } from "../../../flags";

export const updateSession = (newSession: Partial<SystemState>) =>
  action(SystemActionTypes.UPDATE_SESSION, newSession);
export const clearSession = () => action(SystemActionTypes.CLEAR_SESSION);

export const incrementTick = () => action(SystemActionTypes.INCREMENENT_TICK);

export const setFeatureFlag = (featureFlag: Flag) =>
  action(SystemActionTypes.SET_FEATURE_FLAG, featureFlag);
