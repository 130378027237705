import {
  Store,
  createStore,
  applyMiddleware,
  combineReducers,
  compose,
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { logger } from "../../app/middleware";
import { layoutReducer } from "./layout";
import { systemReducer } from "./system";
import thunkMiddleware from "redux-thunk";

const rootReducer = combineReducers({
  system: systemReducer,
  layout: layoutReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export function configureStore(initialState?: AppState): Store<AppState> {
  let middleware = applyMiddleware(thunkMiddleware, logger);

  if (process.env.NODE_ENV !== "production") {
    middleware = composeWithDevTools(middleware);
  }

  const store = createStore(
    rootReducer as any,
    initialState as AppState,
    compose(middleware)
  ) as Store<AppState>;

  return store;
}
