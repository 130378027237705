import React from "react";
import Modal from "react-responsive-modal";

import styled from "styled-components";
import { H1 } from "../layout/Typography";
import { PropertyContact } from "../../../types";
import { BasicButton } from "../layout/Buttons";
import { getInitialsFromName } from "../../utils/initials";

type PropertyContactModalProps = {
  open: boolean;
  onDone: () => void;
  propertyContacts: PropertyContact[];
  propertyName: string;
};

const InnerModal = styled.div`
  margin: 30px;
  @media (max-width: 480px) {
    margin: 8px;
  }
`;

const TileContainer = styled.div`
  margin: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: row;

  @media (max-width: 480px) {
    font-size: 14px;
  }

  [data-initials]:before {
    background: #099bdd;
    color: white;
    opacity: 1;
    content: attr(data-initials);
    display: inline-block;
    font-weight: bold;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 0.5em;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
  }
`;

const Tile = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  margin: 5px;
  background-color: #ffffff;
  color: #000;

  h2 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 300;
    color: #000;
    margin-left: 16px;
  }

  @media (max-width: 480px) {
    h2 {
      font-size: 14px;
    }
  }

  b {
    color: #636363;
  }

  .content {
    margin: 16px;
    word-wrap: break-word;
  }

  a {
    text-decoration: none;
    color: #34495e;
    font-weight: 500;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PropertyContactModal: React.FC<PropertyContactModalProps> = ({
  open,
  onDone,
  propertyContacts,
  propertyName,
}) => {
  return (
    <Modal open={open} onClose={() => onDone()} center>
      <InnerModal>
        <H1>Tengiliðir fyrir {propertyName}</H1>

        <TileContainer>
          {propertyContacts.length > 0 ? (
            propertyContacts.map((contact) => (
              <Tile key={contact.id}>
                <Title>
                  <div data-initials={getInitialsFromName(contact.name)}></div>
                  <h2>{contact.name}</h2>
                </Title>

                <div className="content">
                  {contact.phone && (
                    <p>
                      <b>Sími:</b>{" "}
                      <a href={`tel:+354${contact.phone}`}>{contact.phone}</a>
                    </p>
                  )}
                  {contact.email && (
                    <p>
                      <b>Netfang:</b>{" "}
                      <a href={`mailto:${contact.email}`}>{contact.email}</a>
                    </p>
                  )}
                  {contact.role && (
                    <p>
                      <b>Staða:</b> {contact.role}
                    </p>
                  )}
                </div>
              </Tile>
            ))
          ) : (
            <div>Enginn tengiliður skráður</div>
          )}
        </TileContainer>

        <BasicButton buttonType="primary" onClick={() => onDone()}>
          Loka
        </BasicButton>
      </InnerModal>
    </Modal>
  );
};
