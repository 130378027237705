import React, { useState } from "react";
import Modal from "react-responsive-modal";
import styled, { css } from "styled-components";
import { H1 } from "../layout/Typography";
import { QuestionCircle } from "@styled-icons/bootstrap";
import kennitala from "kennitala";
import {
  ConnectOwnerMutation,
  ConnectOwnerMutationVariables,
  CreateAndConnectOwnerMutation,
  CreateAndConnectOwnerMutationVariables,
  GetOwnerCardBySocialNumberQuery,
  GetOwnerCardBySocialNumberQueryVariables,
  OwnerCard,
} from "../../../types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  connectOwner,
  createdAndConnectOwner,
  ownerCardBySocialNumber,
} from "../../queries/user";
import { BasicButton } from "../layout/Buttons";
import { useEffect } from "react";
import { emailValid } from "../utils/email";
import { toast } from "react-toastify";

type ConnectOwnenModalProps = {
  open: boolean;
  onDone: () => void;
  ownerId: string;
};

const QuestionIcon = styled(QuestionCircle)`
  color: #000;
  height: 32px;
`;

const InnerModal = styled.div`
  margin: 30px;
`;

type InputProps = {
  hasError?: boolean;
  disabled?: boolean;
};
const Input = styled.input<InputProps>`
  height: 40px;
  width: 100%;
  display: block;
  border: ${(props: InputProps) =>
    props.hasError ? css`1px solid #a51818;` : css`1px solid #dedede;`};
  background: transparent;
  font-size: 16px;
  transition: 0.3s ease;
  padding-top: 6px;
  padding-bottom: 0px;
  padding-left: 10px;
  outline: none;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  border-bottom: ${(props: InputProps) =>
    props.hasError ? css`2px solid #a51818;` : css`2px solid #dedede;`};
  color: ${(props: InputProps) =>
    props.hasError ? css`#a51818;` : css`#000;`};
  margin-bottom: 15px;

  :disabled {
    color: #000;
    opacity: 0.6;
  }
`;

const Message = styled.div`
  margin: 18px;
  display: flex;
  align-items: center;
  width: 350px;

  svg {
    margin-right: 8px;
  }
`;

export const ConnectOwnenModal: React.FC<ConnectOwnenModalProps> = ({
  open,
  onDone,
  ownerId,
}) => {
  const [socialNumber, setSocialNumber] = useState<string>("");
  const [debouncedSocialNumber, setDebouncedSocialNumber] = useState<string>(
    ""
  );
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [reason, setReason] = useState<string>("");
  const [fetchedOwner, setFetchedOwner] = useState<OwnerCard>();

  const socialNumberValid = kennitala.isValid(socialNumber || "");

  // Clear on open
  useEffect(() => {
    setSocialNumber("");
    setName("");
    setFetchedOwner(undefined);
    setEmail("");
    setPhone("");
  }, [open]);

  // Update lazy query when chaning to a valid socialnumber.
  useEffect(() => {
    if (kennitala.isValid(socialNumber)) {
      setDebouncedSocialNumber(socialNumber);
      getOwnerCardForSocialNumber();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialNumber]);

  // Get Pottential owner that is on record.
  const [getOwnerCardForSocialNumber] = useLazyQuery<
    GetOwnerCardBySocialNumberQuery,
    GetOwnerCardBySocialNumberQueryVariables
  >(ownerCardBySocialNumber, {
    variables: {
      socialNumber: debouncedSocialNumber,
    },
    onCompleted(data) {
      setName(data.getOwnerCardBySocialNumber.name);
      setFetchedOwner(data.getOwnerCardBySocialNumber);
    },
    onError() {
      setFetchedOwner(undefined);
    },
  });

  const [createdAndConnectOwnerMutation] = useMutation<
    CreateAndConnectOwnerMutation,
    CreateAndConnectOwnerMutationVariables
  >(createdAndConnectOwner, {
    onCompleted() {
      onDone();
    },
    onError(...error) {
      console.error(`Error: ${error}`);
      toast.error(
        "Villa við að bæta íbúa við húsfélag. Hafðu samband við Eignarekstur."
      );
    },
  });

  const [connectOwnerMutation] = useMutation<
    ConnectOwnerMutation,
    ConnectOwnerMutationVariables
  >(connectOwner, {
    onCompleted() {
      onDone();
    },
    onError(...error) {
      console.error(`Error: ${error}`);
      toast.error(
        "Villa við að bæta íbúa við húsfélag. Hafðu samband við Eignarekstur."
      );
    },
  });

  return (
    <Modal open={open} onClose={() => onDone()} center>
      <InnerModal>
        <H1>Tengja íbúa við aðganginn minn</H1>
        <p>
          Tengdir íbúar hafa möguleika að skoða reikninga og skjöl sem þú hefur
          aðgang að
        </p>

        <Input
          hasError={!socialNumberValid && socialNumber !== ""}
          value={socialNumber || ""}
          onChange={(e) => {
            setSocialNumber(e.currentTarget.value.replace(/\D/g, ""));
          }}
          placeholder="Kennitala"
        ></Input>

        <Input
          disabled={fetchedOwner !== undefined || !socialNumberValid}
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          placeholder="Nafn"
        ></Input>

        {!fetchedOwner && (
          <>
            <Input
              disabled={fetchedOwner !== undefined || !socialNumberValid}
              value={phone}
              onChange={(e) => setPhone(e.currentTarget.value)}
              placeholder="Sími"
            ></Input>

            <Input
              disabled={fetchedOwner !== undefined || !socialNumberValid}
              hasError={!emailValid(email) && email !== ""}
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              placeholder="Netfang"
            ></Input>
          </>
        )}

        <Input
          disabled={!socialNumberValid}
          value={reason}
          onChange={(e) => setReason(e.currentTarget.value)}
          placeholder="Ástæða fyrir tengindu"
        ></Input>

        <br />

        <BasicButton
          buttonType="primary"
          onClick={() => {
            if (fetchedOwner && fetchedOwner?.id) {
              connectOwnerMutation({
                variables: {
                  id: fetchedOwner?.id!,
                  reason: "",
                },
              });
            } else {
              createdAndConnectOwnerMutation({
                variables: {
                  input: {
                    name,
                    socialNumber,
                    email,
                    phone,
                  },
                  reason: "",
                },
              });
            }
          }}
          disabled={!socialNumberValid || name === "" || reason === ""}
          fluid
        >
          Tengja íbúa við aðganginn minn
        </BasicButton>

        <br />

        {socialNumberValid && !fetchedOwner && (
          <Message>
            <QuestionIcon />
            Íbúi með kennitöluna {socialNumber} er ekki til á skrá hjá okkur!
            Vinsamlegast sláðu inn réttar upplýsingar um íbúa, áður en þú bætir
            honum við sem íbúa í húsfélaginu.
          </Message>
        )}
      </InnerModal>
    </Modal>
  );
};
