import * as React from "react";
import Header from "../layout/Header";
import { Page } from "../layout/Page";
import { useLocation } from "react-router";
import { LoadingPage } from "../layout/Loading";
import styled from "styled-components";
import isLocale from "date-fns/locale/is";
import { parseISO, format } from "date-fns";
import { getHumanReadableType } from "../../utils/document";
import {
  Table,
  TableHeader,
  TableTr,
  TableTh,
  TableBody,
  TableHeading,
} from "../layout/Table";
import { useLazyQuery, useQuery } from "@apollo/client";
import queryString from "query-string";
import { getGetDocumentsV2 } from "../../queries/documents";
import {
  GetDocumentsV2Query,
  GetDocumentsV2QueryVariables,
  GetPropertyDetailQuery,
  GetPropertyDetailQueryVariables,
} from "../../../types";
import { useEffect, useState } from "react";
import { BasicButton } from "../layout/Buttons";
import { ClassicSpinner } from "react-spinners-kit";
import { getPropertyDetail } from "../../queries/properties";
import { Breadcrumbs, Crumb } from "../layout/BreadCrumbs";

const Container = styled.div`
  word-wrap: break-word;
  white-space: normal;
  padding: 15px;
`;

const InnerCard = styled.div`
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  border-bottom: 2px solid #dedede;
  background-color: #ffffff;
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

export const DocumentPage = () => {
  let location = useLocation();
  let { propertyId } = queryString.parse(location.search);

  const [propertyIdFilter, setPropertyIdFilter] = useState<string>();
  const [dateToFilter] = useState<string>();
  const [dateFromFilter] = useState<string>();
  const [limit] = useState<number>(15);
  const [page] = useState<number>(0);
  const [noMoreResults, setNoMoreResults] = useState(false);

  const documentsQuery = useQuery<
    GetDocumentsV2Query,
    GetDocumentsV2QueryVariables
  >(getGetDocumentsV2, {
    variables: {
      fromDate: dateFromFilter,
      toDate: dateToFilter,
      limit: limit,
      page: page,
      propertyId: propertyIdFilter,
    },
  });

  const [propertyQuery, { data: propertyData }] = useLazyQuery<
    GetPropertyDetailQuery,
    GetPropertyDetailQueryVariables
  >(getPropertyDetail, { variables: { propertyId: propertyId as string } });

  useEffect(() => {
    if (propertyId) {
      setPropertyIdFilter(propertyId as string);
      propertyQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyId]);

  const loading = documentsQuery.loading;

  const documents = documentsQuery.data?.documentsV2 || [];

  return (
    <>
      <Header />
      {loading ? (
        <LoadingPage loadingMessage="Sæki skjölin þín.." />
      ) : (
        <Page>
          <h1 className="page">Gagnabankinn</h1>
          <Breadcrumbs>
            <Crumb>
              <a href="/">Heim</a>
            </Crumb>
            <Crumb>
              <a href="/documents">Gagnabankinn</a>
            </Crumb>
            {propertyId && (
              <Crumb>
                <a href={`/documents?propertyId=${propertyId}`}>
                  {propertyData?.propertyById.address}
                </a>
              </Crumb>
            )}
          </Breadcrumbs>

          <Container>
            <InnerCard>
              <>
                {documents.length > 0 ? (
                  <>
                    <TableHeading>
                      <h2>Skjölin þín</h2>

                      {propertyId ? (
                        <p>
                          Öll skjöl sem eru sýnileg hér eru tengd húsfélaginu "
                          <b>{propertyData?.propertyById.address}</b>"
                        </p>
                      ) : (
                        <p>
                          Öll skjöl sem eru sýnileg hér, eru tengd húsfélögunum
                          þínum.
                        </p>
                      )}
                    </TableHeading>

                    <Table>
                      <TableHeader>
                        <TableTr>
                          <TableTh>Nafn á skjali</TableTh>
                          <TableTh>Flokkur</TableTh>
                          <TableTh className="hide-mobile">Dagsett</TableTh>
                        </TableTr>
                      </TableHeader>
                      <TableBody>
                        {documents.map((document, index) => (
                          <TableTr key={index}>
                            <TableTh>
                              <a href={`/file/${document.id}`}>
                                {document.name}
                              </a>
                            </TableTh>
                            <TableTh>
                              {getHumanReadableType(document.type)}
                            </TableTh>
                            <TableTh className="hide-mobile">
                              {format(
                                parseISO(document.displayDate),
                                "d. MMMM yyyy",
                                {
                                  locale: isLocale,
                                }
                              )}
                            </TableTh>
                          </TableTr>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                ) : (
                  <div>Engin skjöl til að birta.</div>
                )}
                <div style={{ textAlign: "center", margin: "16px" }}>
                  <BasicButton
                    buttonType="primary"
                    disabled={noMoreResults}
                    onClick={() => {
                      documentsQuery.fetchMore({
                        variables: {
                          fromDate: dateFromFilter,
                          toDate: dateToFilter,
                          limit: limit,
                          page: documents.length,
                          propertyId: propertyIdFilter,
                        },
                        updateQuery: (
                          prev: GetDocumentsV2Query,
                          { fetchMoreResult }
                        ) => {
                          if (
                            !fetchMoreResult ||
                            fetchMoreResult.documentsV2.length === 0
                          ) {
                            setNoMoreResults(true);
                            return prev;
                          }
                          return Object.assign({}, prev, {
                            documentsV2: [
                              ...(prev.documentsV2 || []),
                              ...(fetchMoreResult.documentsV2 || []),
                            ],
                          });
                        },
                      });
                    }}
                  >
                    <ClassicSpinner
                      size={15}
                      loading={documentsQuery.loading}
                    />{" "}
                    {noMoreResults
                      ? "Öll möguleg skjöl sýnileg"
                      : "Sækja meira"}
                  </BasicButton>
                </div>
              </>
            </InnerCard>
          </Container>
        </Page>
      )}
    </>
  );
};

export default DocumentPage;
