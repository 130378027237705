import styled from "styled-components";

export const H1 = styled.h1`
  margin: 0;
  padding: 5px 0px 0px 10px;
  font-weight: 300;
  color: #656464;

  font-size: 24px;

  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

export const H3 = styled.h3`
  margin: 0;
  padding: 5px 0px 0px 10px;
  font-weight: 300;
`;

export const P = styled.p`
  margin: 0;
  padding: 5px 0px 0px 10px;
  font-weight: 300;
`;
