import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { useMutation } from "@apollo/client";
import {
  ApproveInvoiceLineMutation,
  ApproveInvoiceLineMutationVariables,
  InvoiceApproval,
} from "../../../types";
import styled from "styled-components";
import { H1 } from "../layout/Typography";
import { approveInvoiceLine } from "../../queries/invoices";
import { BasicButton } from "../layout/Buttons";
import { toast } from "react-toastify";

type ApproveInvoiceModalProps = {
  open: boolean;
  onDone: () => void;
  approval: InvoiceApproval;
  propertyName: string;
  socialNumber: string;
};

const InnerModal = styled.div`
  margin: 30px;
`;

const InputContainer = styled.div`
  text-align: right;
`;

const RejectReasonInput = styled.input`
  height: 40px;
  width: 100%;
  display: block;
  border: none;
  background: transparent;
  font-size: 16px;
  transition: 0.3s ease;
  padding-top: 6px;
  padding-bottom: 0px;
  padding-left: 10px;
  margin-top: 18px;
  outline: none;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15);
  border-bottom: 2px solid #dedede;
  margin-bottom: 15px;

  :disabled {
    background-color: #ececec;
    color: #d4d4d4;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Input = styled.div`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }

  span:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + span {
    background-color: #2196f3;
  }

  input:focus + span {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + span:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`;

export const ApproveInvoiceModal: React.FC<ApproveInvoiceModalProps> = ({
  open,
  onDone,
  approval,
  propertyName,
  socialNumber,
}) => {
  const [rejectReason, setRejectReason] = useState<string>("");
  const [approved, setApproved] = useState<boolean>(false);

  const isSubmittable = () =>
    (rejectReason !== "" && approved === false) ||
    (approved === true && approved !== approval.approved);

  // Mutation: Update Profile From API
  const [approveInvoiceLineMutation] = useMutation<
    ApproveInvoiceLineMutation,
    ApproveInvoiceLineMutationVariables
  >(approveInvoiceLine, {
    onCompleted() {
      onDone();
    },
    onError(error) {
      console.error(`Error: ${error}`);
      toast.error("Villa við að samþykkja reikning.");
    },
  });

  useEffect(() => {
    setRejectReason("");
    setApproved(approval.approved);
  }, [approval, propertyName]);

  return (
    <Modal open={open} onClose={() => onDone()} center>
      <InnerModal>
        <H1>Uppfæra stöðu á óbókuðum reikning.</H1>
        <p>Hér getur þú uppfært stöðu á óbókuðum reikning </p>

        <InputContainer>
          <InputWrapper>
            <div>Samþykktur reikningur</div>
            <Input>
              <label>
                <input
                  checked={approved}
                  onChange={(ev) => {
                    setApproved(ev.target.checked);
                  }}
                  type="checkbox"
                />
                <span></span>
              </label>
            </Input>
          </InputWrapper>

          <RejectReasonInput
            type="textarea"
            value={rejectReason}
            disabled={approved}
            onChange={(e) => setRejectReason(e.currentTarget.value)}
            placeholder="Ástæða fyrir höfnun reiknings"
          ></RejectReasonInput>

          <BasicButton
            buttonType="primary"
            disabled={!isSubmittable()}
            onClick={() => {
              approveInvoiceLineMutation({
                variables: {
                  propertyName: propertyName,
                  socialNumber: socialNumber,
                  approve: approved,
                  invoiceNo: approval.approvalNo,
                  rejectReason: rejectReason,
                },
              });
            }}
          >
            {approved ? "Samþykkja" : "Hafna"} reikning
          </BasicButton>
        </InputContainer>
      </InnerModal>
    </Modal>
  );
};
