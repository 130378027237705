import * as React from "react";
import Header from "../layout/Header";
import { Page } from "../layout/Page";
import { useParams } from "react-router";
import { LoadingPage } from "../layout/Loading";
import { useMutation, useQuery } from "@apollo/client";
import {
  GetOwnerCardsByPropertyQuery,
  GetOwnerCardsByPropertyQueryVariables,
  UnassignOwnerFromPropertyMutation,
  UnassignOwnerFromPropertyMutationVariables,
} from "../../../types";
import { ownersCardsForProperty } from "../../queries/user";
import { BasicButton, BasicRedButton } from "../layout/Buttons";
import {
  Table,
  TableHeader,
  TableTr,
  TableTh,
  TableBody,
} from "../layout/Table";
import { CardContainer, InnerCard } from "../layout/CardCommon";
import styled from "styled-components";
import { unassignOwnerFromProperty } from "../../queries/properties";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { AssignOwnerToPropertyModal } from "../modals/AssignOwnerToPropertyModal";
import { useState } from "react";
import { ErrorPage } from "../layout/Error";
import { Breadcrumbs, Crumb } from "../layout/BreadCrumbs";

const TableHeading = styled.div`
  padding: 16px;
  border-bottom: 1px solid #dadada;
`;

export const PropertyManagePage = () => {
  const [showAssignOwnerToProperty, setShowAssignOwnerToProperty] = useState<
    boolean
  >(false);

  let { propertyId } = useParams<{ propertyId: string }>();

  const ownerCardsQuery = useQuery<
    GetOwnerCardsByPropertyQuery,
    GetOwnerCardsByPropertyQueryVariables
  >(ownersCardsForProperty, {
    variables: {
      propertyId: propertyId,
    },
    fetchPolicy: "cache-and-network",
  });

  const [unassignOwnerMutation] = useMutation<
    UnassignOwnerFromPropertyMutation,
    UnassignOwnerFromPropertyMutationVariables
  >(unassignOwnerFromProperty);

  const ownerCards = ownerCardsQuery.data?.getOwnerCardsByProperty || [];
  const property = ownerCardsQuery.data?.propertyById;

  return (
    <>
      <AssignOwnerToPropertyModal
        propertyId={propertyId}
        open={showAssignOwnerToProperty}
        onDone={() => {
          setShowAssignOwnerToProperty(false);
          ownerCardsQuery.refetch();
        }}
      />

      <Header />
      {ownerCardsQuery.loading ? (
        <LoadingPage loadingMessage="Sæki íbúa.." />
      ) : !ownerCardsQuery.error ? (
        <Page>
          <>
            <h1 className="page">Stórnun húsfélags</h1>
            <Breadcrumbs>
              <Crumb>
                <a href="/">Heim</a>
              </Crumb>
              <Crumb>
                <a href="/">Stjórnun íbúa</a>
              </Crumb>
              {propertyId && (
                <Crumb>
                  <a href={`/property/${propertyId}/management`}>
                    {ownerCardsQuery?.data?.propertyById.address}
                  </a>
                </Crumb>
              )}
            </Breadcrumbs>
            <CardContainer>
              <InnerCard>
                <TableHeading>
                  <h2>Íbúar í {property?.address}</h2>Í listanum eru allir íbúar
                  sem hafa aðgang að húsfélaginu "{property?.address}".
                  <br />
                  Þú sem stjórnandi húsfélagsins getur bætt við nýjum íbúum eða
                  tekið út íbúa. <br />
                  <br />
                  <BasicButton
                    buttonType="primary"
                    onClick={() => {
                      setShowAssignOwnerToProperty(true);
                    }}
                  >
                    Bæta við íbúa
                  </BasicButton>
                </TableHeading>

                <Table>
                  <TableHeader>
                    <TableTr>
                      <TableTh className="hide-mobile">Nafn á íbúa</TableTh>
                      <TableTh className="hide-mobile">Kennitala</TableTh>
                      <TableTh className="show-mobile">Nafn á íbúa</TableTh>
                      <TableTh></TableTh>
                    </TableTr>
                  </TableHeader>
                  <TableBody>
                    {ownerCards.map((owner, index) => (
                      <TableTr key={index}>
                        <TableTh className="hide-mobile">{owner.name}</TableTh>
                        <TableTh className="hide-mobile">
                          {owner.socialNumber}
                        </TableTh>
                        <TableTh className="show-mobile">
                          {owner.name} ({owner.socialNumber})
                        </TableTh>
                        <TableTh>
                          <BasicRedButton
                            onClick={() => {
                              confirmAlert({
                                title: "Ertu viss?",
                                message: "Þú ert að taka íbúa úr húsfélaginu.",
                                buttons: [
                                  {
                                    label: "Já",
                                    onClick: () => {
                                      unassignOwnerMutation({
                                        variables: {
                                          propertyId: propertyId,
                                          ownerId: owner.id || "",
                                        },
                                      }).then(() => {
                                        ownerCardsQuery.refetch();
                                      });
                                    },
                                  },
                                  {
                                    label: "Nei",
                                    onClick: () => {},
                                  },
                                ],
                              });
                            }}
                          >
                            Taka úr húsfélagi
                          </BasicRedButton>
                        </TableTh>
                      </TableTr>
                    ))}
                  </TableBody>
                </Table>
              </InnerCard>
            </CardContainer>
          </>
        </Page>
      ) : (
        <ErrorPage errorMessage="Villa við að sækja upplýsingar um húsfélag." />
      )}
    </>
  );
};

export default PropertyManagePage;
