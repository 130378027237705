import styled, { css } from "styled-components";

type BasicButtonProps = {
  fluid?: boolean;
  buttonType?: "primary" | "secondary";
};
export const BasicButton = styled.button<BasicButtonProps>`
  font-family: "Muli", sans-serif;
  font-weight: 400;
  line-height: 24px;
  background: ${(props: BasicButtonProps) =>
    props.buttonType === "primary" ? "#069" : "#f2f6f9"};
  border: ${(props: BasicButtonProps) =>
    props.buttonType === "primary" ? "1px solid #069" : "1px solid #1e88e5"};
  padding: 12px;
  color: ${(props: BasicButtonProps) =>
    props.buttonType === "primary" ? "#fff" : "#1e88e5"};
  cursor: pointer;
  :disabled {
    pointer-events: none;
    opacity: 0.5;
    color: #fff;
  }
  ${(props: BasicButtonProps) =>
    props.fluid &&
    css`
      width: 100%;
      justify-content: center;
    `};
  :hover {
    background: ${(props: BasicButtonProps) =>
      props.buttonType === "primary" ? "#0170a8" : "#c1c1c2"};
  }
  :focus {
    border: ${(props: BasicButtonProps) =>
      props.buttonType === "primary"
        ? "1px solid #66808e"
        : "1px solid #6d8295"};
  }

  display: inline-flex;
  align-items: center;
`;

export const LinkButton = styled.a`
  padding: 8px;
  text-decoration: none;
  background-color: #069;
  color: #fff;
  padding: 8px;
  margin: 4px;
`;

export const BasicRedButton = styled.button`
  background: #b31b00;
  border: none;
  padding: 8px;
  color: #fff;
  cursor: pointer;
  :disabled {
    background: #f1d4cf;
  }
`;

export const BasicGreenButton = styled.button`
  background: #13700a;
  border: none;
  padding: 8px;
  color: #fff;
  cursor: pointer;
  :disabled {
    background: #f1d4cf;
  }
`;

export const BasicColoredButton = styled.button<{ color: string }>`
  background: ${(props) => props.color};
  border: none;
  padding: 8px;
  color: #fff;
  cursor: pointer;
  :disabled {
    background: #f1d4cf;
  }
`;

export const IconButton = styled.button`
  background: transparent;
  border: none;
  padding: 8px;
  color: #000;
  cursor: pointer;
`;
