import React, { useState } from "react";
import styled from "styled-components";
import { HomeHeart } from "@styled-icons/remix-line";
import { AdminPanelSettings } from "@styled-icons/material-outlined";
import { Link } from "@styled-icons/evil";
import { PiggyBank } from "@styled-icons/fa-solid";
import { Contact } from "@styled-icons/boxicons-solid";
import { H1 } from "../layout/Typography";
import { Loading } from "../layout/Loading";
import {
  AccessType,
  GetPropertiesForDashboardQuery,
  GetPropertiesForDashboardQueryVariables,
  PropertyContact,
  PropertySupportRequest,
} from "../../../types";
import { getPropertiesForDashboard } from "../../queries/properties";
import { cleanPropertyAddress } from "../utils/text_utils";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { setCollapseCard } from "../../store/layout";
import {
  TitleContainer,
  TitleLeft,
  CollapsedName,
  TitleRight,
  CaretDownIcon,
  CaretRightIcon,
  CardActionButton,
} from "./CardCommon";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";
import { PropertyContactModal } from "../modals/PropertyContactModal";
import { PropertySupportRequestModal } from "../modals/PropertySupportRequestModal";
import { useQuery } from "@apollo/client";
import { Tooltip } from "../layout/Tooltip";

const LoadingCard = styled(Loading)`
  height: 200px;
`;

const Icon = styled(HomeHeart)`
  color: #cecece;
  height: 52px;
  align-self: flex-start;
`;

const ManageIcon = styled(AdminPanelSettings)`
  color: #fff;
  height: 24px;
  margin-right: 4px;
`;

const LinkIcon = styled(Link)`
  color: #069;
  height: 18px;
`;

const FinanceIcon = styled(PiggyBank)`
  color: #fff;
  height: 24px;
  margin-right: 4px;
`;

const ContactIcon = styled(Contact)`
  color: #fff;
  height: 24px;
  margin-right: 4px;
`;

const Container = styled.div`
  word-wrap: break-word;
  white-space: normal;
  padding: 15px;
`;

const InnerCard = styled.div`
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  border-bottom: 2px solid #dedede;
  background-color: #fbfbfd;
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

const TileContainer = styled.div`
  margin: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: row;
`;

const Tile = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: 200px;
  flex-direction: column;
  justify-content: space-between;

  max-width: 100%;
  min-height: 10rem;
  margin: 5px;
  border: 1px solid #dedede;
  background-color: #ffffff;
  color: gray;

  // End Color
  @media (max-width: 600px) {
    flex-basis: 100% !important;
    max-width: 100%;
  }

  h2 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 300;
    color: #000;
    margin-left: 16px;
  }

  b {
    color: #069;
  }

  .content {
    margin: 16px;
    word-wrap: break-word;
  }

  .buttonContainer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;

    button {
      flex: 1 1 auto;
      background: #069;
      color: #ffffff;
      position: relative;
      text-align: center;
      border: 0;
      line-height: 40px;
      border-top: 1px solid #fff;
      border-left: 1px solid #fff;
      margin-top: -1px;
      margin-left: -1px;
    }
  }

  .two-buttons {
    @media (min-width: 600px) and (max-width: 728px) {
      button:first-of-type {
        border-bottom: solid 1px white;
      }
    }
  }

  a {
    text-decoration: none;
    color: #34495e;
    font-weight: 500;
  }
`;

const InfoContainer = styled.div`
  margin: 24px;
  hr {
    border-top: 1px solid #f6f6f6;
  }
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  b {
    color: #000000;
    font-weight: 500;
    font-size: 18px;
  }

  button {
    border: 0;
    background: transparent;
    cursor: pointer;
    margin: 0;
    padding: 0;
    color: #34495e;
  }
`;

export type Props = {};

export const PropertiesCard: React.SFC<Props> = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showContactModals, setShowContactModals] = useState<{
    open: boolean;
    propertyContacts: PropertyContact[];
    propertyName: string;
  }>();

  const [showSupportRequestModals, setShowSupportRequestModals] = useState<{
    open: boolean;
    supportRequests: PropertySupportRequest[];
    propertyName: string;
  }>();

  /* COLLAPSE */
  const { collapsed_card } = useSelector((state: AppState) => state.layout);
  const DEFAULT_COLLAPSED = true;
  const CARD_COLLAPSED = (KEY: string) =>
    collapsed_card[KEY] === undefined ? DEFAULT_COLLAPSED : collapsed_card[KEY];

  const getPropertiesForDashboardQuery = useQuery<
    GetPropertiesForDashboardQuery,
    GetPropertiesForDashboardQueryVariables
  >(getPropertiesForDashboard, {
    errorPolicy: "ignore",
    onError(...error) {
      console.log("Error when fetching all info about properties:", error);
    },
  });

  /* disabled for now.
  const invoicesResponse = useQuery<
    GetPropertiesInvoicesForDashboardQuery,
    GetPropertiesInvoicesForDashboardQueryVariables
  >(getPropertiesInvoicesForDashboard, {
    onError(...error) {
      toast.error(
        `Villa: Get ekki sótt upplýsingar um reikninga. Reyndu aftur síðar.`
      );
    },
  });
  */

  const properties = getPropertiesForDashboardQuery.data?.properties || [];

  return (
    <>
      {(showContactModals?.open || false) && (
        <PropertyContactModal
          open={showContactModals?.open || false}
          onDone={() => {
            ReactGA.event({
              category: "Contacts",
              action: "Open contacts for property",
            });
            setShowContactModals(undefined);
          }}
          propertyContacts={showContactModals?.propertyContacts || []}
          propertyName={showContactModals?.propertyName || ""}
        />
      )}
      {(showSupportRequestModals?.open || false) && (
        <PropertySupportRequestModal
          open={showSupportRequestModals?.open || false}
          onDone={() => {
            ReactGA.event({
              category: "Contacts",
              action: "Open contacts for property",
            });
            setShowSupportRequestModals(undefined);
          }}
          requests={showSupportRequestModals?.supportRequests || []}
          propertyName={showSupportRequestModals?.propertyName || ""}
        />
      )}
      {getPropertiesForDashboardQuery.loading ? (
        <Container>
          <InnerCard>
            <LoadingCard loadingMessage="Hleð inn upplýsingum um húsfélög" />
          </InnerCard>
        </Container>
      ) : properties.length === 0 ? (
        <Container>
          <InnerCard>
            Þú virðist ekki vera með aðgang að neinu húsfélagi.
          </InnerCard>
        </Container>
      ) : (
        <>
          {properties.map((property) => (
            <Container key={property.id}>
              <InnerCard>
                {CARD_COLLAPSED("property-" + property.id) ? (
                  <>
                    <TitleContainer>
                      <TitleLeft>
                        <Icon />
                        <CollapsedName>
                          <H1>{cleanPropertyAddress(property.address)}</H1>
                          <p>Húsfélag</p>
                        </CollapsedName>
                      </TitleLeft>
                      <TitleRight>
                        <Tooltip tooltipMessage="Loka húsfélagsfleka">
                          <CaretDownIcon
                            onClick={() =>
                              dispatch(
                                setCollapseCard(
                                  "property-" + property.id,
                                  false
                                )
                              )
                            }
                          />
                        </Tooltip>
                      </TitleRight>
                    </TitleContainer>

                    <InfoContainer>
                      <InfoRow>
                        <b>Fjöldi</b>
                        <span>
                          {property.ownersCount}{" "}
                          {property.ownersCount === 1
                            ? "skráður íbúi"
                            : "skráðir íbúar"}
                        </span>
                      </InfoRow>
                      <hr />
                      <InfoRow>
                        <b>Aðgangur</b>
                        <span>
                          Þú ert{" "}
                          {property.access === AccessType.Basic
                            ? "íbúi"
                            : "stjórnandi"}
                        </span>
                      </InfoRow>
                      <hr />
                      <InfoRow>
                        <b>Tengiliðir</b>
                        {property.contacts.length > 0 ? (
                          <button
                            aria-label="Skoða tengiliði"
                            onClick={() => {
                              setShowContactModals({
                                open: true,
                                propertyContacts: property.contacts,
                                propertyName: property.address,
                              });
                            }}
                          >
                            <Tooltip tooltipMessage="Skoða tengiliði fyrir húsfélagið">
                              {property.contacts.length}{" "}
                              {property.contacts.length === 1
                                ? "tengiliður"
                                : "tengiliðir"}{" "}
                              á skrá
                              <LinkIcon />
                            </Tooltip>
                          </button>
                        ) : (
                          <span>Enginn tengiliður skráður</span>
                        )}
                      </InfoRow>
                      <hr />
                      <InfoRow>
                        <b>Lokaðar beiðnir</b>
                        {(property?.propertySupportRequests || []).length >
                        0 ? (
                          <button
                            aria-label="Skoða beiðnir"
                            onClick={() => {
                              setShowSupportRequestModals({
                                open: true,
                                supportRequests:
                                  property?.propertySupportRequests || [],
                                propertyName: property.address,
                              });
                            }}
                          >
                            <Tooltip tooltipMessage="Skoða allar beiðnir">
                              {(property?.propertySupportRequests || []).filter(
                                (p) => p.finished
                              ).length + " beiðnir"}
                              <LinkIcon />
                            </Tooltip>
                          </button>
                        ) : (
                          <span>Engin beiðni til</span>
                        )}
                      </InfoRow>
                      <hr />
                      <InfoRow>
                        <b>Beiðnir í vinnslu</b>
                        {(property?.propertySupportRequests || []).length >
                        0 ? (
                          <button
                            aria-label="Skoða beiðnir"
                            onClick={() => {
                              setShowSupportRequestModals({
                                open: true,
                                supportRequests:
                                  property?.propertySupportRequests || [],
                                propertyName: property.address,
                              });
                            }}
                          >
                            <Tooltip tooltipMessage="Skoða allar beiðnir fyrir húsfélagið">
                              {(property?.propertySupportRequests || []).filter(
                                (p) => !p.finished
                              ).length + " beiðnir"}
                              <LinkIcon />
                            </Tooltip>
                          </button>
                        ) : (
                          <span>Engin beiðni til</span>
                        )}
                      </InfoRow>
                      <hr />
                      <InfoRow>
                        <b>Skjöl</b>
                        <button
                          aria-label="Skoða skjöl fyrir húsfélag"
                          onClick={() => {
                            history.push(
                              `/documents?propertyId=${property.id}`
                            );
                          }}
                        >
                          <Tooltip tooltipMessage="Skoða öll skjöl fyrir húsfélagið">
                            {`${property.documentsCount} ${
                              property.documentsCount === 1 ? "skjal" : "skjöl"
                            } skráð`}
                            <LinkIcon />
                          </Tooltip>
                        </button>
                      </InfoRow>
                      <hr />
                      {property.informations.map((info, i) => (
                        <div key={`info-${i}`}>
                          <InfoRow>
                            <b>{info.title}</b>
                            <span>{info.value}</span>
                          </InfoRow>
                          <hr />
                        </div>
                      ))}
                    </InfoContainer>

                    <TileContainer>
                      {property.access === AccessType.Full && (
                        <Tile>
                          <h2>Stjórnun</h2>
                          <div className="content">
                            <p>
                              Þú ert skráður með <b>full réttindi</b> fyrir
                              húsfélagið. Þú hefur möguleika á að skoða{" "}
                              fjárhagsupplýsingar og stjórnað íbúum í
                              húsfélaginu.
                            </p>

                            {/*}
                            <p>
                              <b>
                                {invoicesResponse.data?.properties
                                  .find((p) => p.id === property.id)
                                  ?.invoiceApprovals?.filter(
                                    (x) => x.approvalStatus === "Nýr"
                                  ).length || 0}
                              </b>{" "}
                              ósamþykktir reikningar til staða í húsfélaginu.{" "}
                              <br />
                              <b>
                                {invoicesResponse.data?.properties
                                  .find((p) => p.id === property.id)
                                  ?.invoiceApprovals?.filter(
                                    (x) => x.approvalStatus !== "Nýr"
                                  ).length || 0}
                              </b>{" "}
                              samþykktir reikningar sem ekki er búið að bóka.
                                  </p> */}
                          </div>

                          <div className="buttonContainer two-buttons">
                            <CardActionButton
                              aria-label="Stjórna íbúum í húsfélagi"
                              buttonType="primary"
                              onClick={() => {
                                history.push(
                                  `/property/${property.id}/management`
                                );
                              }}
                            >
                              <ManageIcon /> Stjórna íbúum í húsfélagi
                            </CardActionButton>
                            <CardActionButton
                              aria-label="Skoða fjárhagsupplýsingar"
                              buttonType="primary"
                              onClick={() => {
                                history.push(
                                  `/property/${property.id}/financials`
                                );
                              }}
                            >
                              <FinanceIcon /> Fjárhagsupplýsingar
                            </CardActionButton>
                            <CardActionButton
                              aria-label="Stjórna tengiliðum"
                              buttonType="primary"
                              onClick={() => {
                                history.push(
                                  `/property/${property.id}/contacts`
                                );
                              }}
                            >
                              <ContactIcon /> Stjórna tengiliðum
                            </CardActionButton>
                          </div>
                        </Tile>
                      )}
                    </TileContainer>
                  </>
                ) : (
                  <TitleContainer>
                    <TitleLeft>
                      <Icon />
                      <CollapsedName>
                        <H1>{cleanPropertyAddress(property.address)}</H1>
                        <p>Húsfélag</p>
                      </CollapsedName>
                    </TitleLeft>
                    <TitleRight>
                      <Tooltip tooltipMessage="Opna húsfélagsfleka">
                        <CaretRightIcon
                          onClick={() =>
                            dispatch(
                              setCollapseCard("property-" + property.id, true)
                            )
                          }
                        />
                      </Tooltip>
                    </TitleRight>
                  </TitleContainer>
                )}
              </InnerCard>
            </Container>
          ))}
        </>
      )}
    </>
  );
};
