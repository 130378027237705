import * as React from "react";
import Header from "../layout/Header";
import { Page } from "../layout/Page";
import { useParams, useHistory } from "react-router";
import { useQuery } from "@apollo/client";
import {
  GetPropertyDetailQuery,
  GetPropertyDetailQueryVariables,
} from "../../../types";
import { LoadingPage } from "../layout/Loading";
import { getPropertyDetail } from "../../queries/properties";
import { ErrorPage } from "../layout/Error";
import styled from "styled-components";
import { BasicButton } from "../layout/Buttons";

const WrapperPropertyDetails = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: 35px;
`;

const HeadlineWrapper = styled.div`
  margin: 25px;
`;
const PropertyHeadline = styled.h1`
  font-weight: 300;
`;

const BodyWrapper = styled.div`
  width: 100%;
`;

const BackButtonWrapper = styled.div`
  text-align: center;
  margin: 20px;
`;

const PropertyHeadLineDescription = styled.div``;

export const PropertyPage = () => {
  let { propertyId } = useParams<{ propertyId: string }>();
  let history = useHistory();

  const propertyDetailQuery = useQuery<
    GetPropertyDetailQuery,
    GetPropertyDetailQueryVariables
  >(getPropertyDetail, {
    variables: {
      propertyId: propertyId,
    },
    fetchPolicy: "cache-and-network",
  });
  const loading = propertyDetailQuery.loading;
  const property = propertyDetailQuery.data?.propertyById;

  return (
    <>
      <Header />
      {loading ? (
        <LoadingPage loadingMessage="Sæki upplýsingar um húsfélagið þitt.." />
      ) : (
        <Page>
          {property ? (
            <WrapperPropertyDetails>
              <HeadlineWrapper>
                <PropertyHeadline>{property.address}</PropertyHeadline>
                <PropertyHeadLineDescription>
                  <p>
                    <b>Einkenni</b>: {property.id}
                  </p>
                  {property.externalId && (
                    <p>
                      <b>Númer</b>: {property.registrationId}
                    </p>
                  )}
                  {property.registrationId && (
                    <p>
                      <b>Kennitala</b>: {property.registrationId}
                    </p>
                  )}
                </PropertyHeadLineDescription>
              </HeadlineWrapper>
              <BodyWrapper>
                <BackButtonWrapper>
                  <BasicButton
                    buttonType="secondary"
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    Aftur á forsíðu
                  </BasicButton>
                </BackButtonWrapper>
              </BodyWrapper>
            </WrapperPropertyDetails>
          ) : (
            <ErrorPage errorMessage="Villa við að sækja upplýsingar um húsfélag." />
          )}
        </Page>
      )}
    </>
  );
};

export default PropertyPage;
