import styled, { css } from "styled-components";

type PropertyInfoBankAmountProps = { amount: string };
export const PropertyInfoBankAmount = styled.div`
  font-weight: 500;
  font-size: 16px;
  ${(props: PropertyInfoBankAmountProps) =>
    parseInt(props.amount) > 0
      ? css`
          color: #046307;
        `
      : props.amount === "0"
      ? css`
          color: #656464;
        `
      : css`
          color: #d2222d;
        `}
  margin: 0;
`;

export const PropertyInfoContainer = styled.div``;
export const PropertyInfoItem = styled.div`
  padding: 5px;
  padding-bottom: 16px;
  ul {
    list-style: none;
    padding-left: 5px;
  }
`;

export const PropertyInfoFlexItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;
  padding-bottom: 16px;
  ul {
    list-style: none;
    padding-left: 5px;
  }
`;

export const PropertyInfoName = styled.h3`
  font-weight: 500;
  font-size: 18px;
  color: #656464;
  margin: 0;
`;

export const PropertyInfoRole = styled.h4`
  font-weight: 400;
  font-size: 14px;
  color: #757575;
  margin: 0 0 4px 0;
`;

export const PropertyInfoContact = styled.h4`
  font-weight: 500;
  font-size: 16px;
  color: #757575;
  margin: 0;
  a:link {
    text-decoration: none;
    color: #757575;
  }

  a:visited {
    text-decoration: none;
    color: #757575;
  }

  a:hover {
    text-decoration: underline;
  }

  a:active {
    text-decoration: underline;
  }
  b {
    color: #656464;
    font-weight: 500;
    font-size: 18px;
  }
`;

export const CardContainer = styled.div`
  word-wrap: break-word;
  white-space: normal;
  padding: 15px;
`;

export const InnerCard = styled.div`
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  border-bottom: 2px solid #dedede;
  background-color: #ffffff;
  padding: 15px;
  display: flex;
  flex-direction: column;
`;
