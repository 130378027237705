import React from "react";
import styled from "styled-components";

const Container = styled.div`
  word-wrap: break-word;
  white-space: normal;
  padding: 15px;
`;

const InnerCard = styled.div`
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  border-bottom: 2px solid #dedede;
  background-color: #ffffff;
  padding: 15px;
`;

export const BasicCard: React.SFC = ({ children }) => {
  return (
    <Container>
      <InnerCard>{children}</InnerCard>
    </Container>
  );
};
