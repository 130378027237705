import { ImpulseSpinner } from "react-spinners-kit";
import * as React from "react";
import styled from "styled-components";

const WrapperPage = styled.div`
  display: flex; /* establish flex container */
  flex-direction: column; /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center; /* center items horizontally, in this case */
  position: fixed;
  margin: auto;
  width: 100%;
  height: 100%;
  .text {
    color: #757575;
    padding: 15px;
  }
`;

const WrapperSimple = styled.div`
  display: flex; /* establish flex container */
  flex-direction: column; /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center; /* center items horizontally, in this case */
  margin: auto;
  width: 100%;
  height: 100%;
  .text {
    color: #757575;
    padding: 15px;
  }
`;

interface LoadingProps {
  className?: string;
  loadingMessage?: string;
}

export const LoadingPage: React.SFC<LoadingProps> = ({
  className,
  loadingMessage,
}) => (
  <WrapperPage className={className}>
    <ImpulseSpinner size={40} frontColor="#000" backColor="#fff" loading />
    {loadingMessage && <div className="text">{loadingMessage}</div>}
  </WrapperPage>
);

export const Loading: React.SFC<LoadingProps> = ({
  className,
  loadingMessage,
}) => (
  <WrapperSimple className={className}>
    <ImpulseSpinner size={40} frontColor="#000" backColor="#fff" loading />
    {loadingMessage && <div className="text">{loadingMessage}</div>}
  </WrapperSimple>
);
