import { gql } from "@apollo/client";

export const getDashboardInfo = gql`
  query GetProfileInfo {
    me {
      userId
      socialNumber
      name
      email
      phone
      userType
      session {
        expiration
      }
    }
    properties {
      address
      id
    }
    connectedOwners {
      userId
      socialNumber
      name
      email
      phone
      connectedToProperties {
        id
        address
        externalId
      }
    }
    ownerConnections {
      userId
      socialNumber
      name
      email
      phone
      properties {
        id
        address
        externalId
      }
    }
  }
`;
