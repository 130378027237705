import * as React from "react";
import Header from "../layout/Header";
import { Page } from "../layout/Page";
import {
  GetAllowedPropertiesQuery,
  GetAllowedPropertiesQueryVariables,
  InvoicesByPropertyNameQuery,
  InvoicesByPropertyNameQueryVariables,
} from "../../../types";
import {
  getAllowedProperties,
  getInvoiceByProperty,
} from "../../queries/invoices";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useEffect } from "react";
import isLocale from "date-fns/locale/is";
import { cleanPropertyAddress } from "../utils/text_utils";
import styled from "styled-components";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeading,
  TableTh,
  TableTr,
} from "../layout/Table";
import { parseISO, isBefore, format } from "date-fns";
import { Link } from "react-router-dom";
import { toBase64 } from "../../../utils/base64";
import { formatAmount } from "../utils/amount";
import { Loading } from "../layout/Loading";
import { Warning } from "@styled-icons/entypo";
import { ExclamationCircle } from "@styled-icons/bootstrap";
import { Breadcrumbs, Crumb } from "../layout/BreadCrumbs";

const DropDown = styled.select`
  padding: 8px 8px 8px 8px;
  margin-left: 8px;
  font-size: 16px;
  border: 1px solid #dadada;
  color: #000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-flex;
  justify-content: center;
  background: #fff;
  font-weight: 800;

  ::-ms-expand {
    display: none; /* remove default arrow on ie10 and ie11 */
  }
`;

const EmptyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  svg {
    margin-right: 8px;
  }
`;

const WarningIcon = styled(Warning)`
  height: 24px;
`;

const AttentionIcon = styled(ExclamationCircle)`
  height: 24px;
`;

const Container = styled.div`
  word-wrap: break-word;
  white-space: normal;
  padding: 15px;
`;

const InnerCard = styled.div`
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  border-bottom: 2px solid #dedede;
  background-color: #ffffff;
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

export type InvoiceWithPropertyId = {
  propertyId: string;
  propertyAddress: string;
  invoiceNo: string;
  socialNumber: string;
  amount: string;
  dueDate: string;
  remainingAmount: string;
};

export const InvoicesPage = () => {
  let [selectedProperty, setSelectedProperty] = React.useState<string>("");

  const allProperties = useQuery<
    GetAllowedPropertiesQuery,
    GetAllowedPropertiesQueryVariables
  >(getAllowedProperties, {
    variables: {},
  });

  const [invoiceQuery, { loading, data: invoicesResponse }] = useLazyQuery<
    InvoicesByPropertyNameQuery,
    InvoicesByPropertyNameQueryVariables
  >(getInvoiceByProperty, {
    variables: {
      propertyName: selectedProperty,
      take: 20, // Only show 20 invoices.
    },
  });
  const invoices = invoicesResponse?.invoicesByPropertyName || [];

  useEffect(() => {
    if (selectedProperty !== "") invoiceQuery();
  }, [invoiceQuery, selectedProperty]);

  let myProperties = (allProperties.data?.properties || []).map(
    (property) => property.address
  );
  const connectedProperties = (
    allProperties.data?.connectedOwners || []
  ).flatMap((owners) =>
    owners.connectedToProperties.map((property) => property.address)
  );

  // Sort and make sure the dropdown values are unique.
  myProperties = myProperties.concat(connectedProperties);
  myProperties = [...new Set(myProperties)].sort();

  return (
    <>
      <Header />

      <Page>
        <h1 className="page">Reikningar</h1>

        <Breadcrumbs>
          <Crumb>
            <a href="/">Heim</a>
          </Crumb>
          <Crumb>
            <a href="/invoices">Reikningar</a>
          </Crumb>
        </Breadcrumbs>

        <Container>
          <InnerCard>
            <TableHeading>
              {selectedProperty === ""
                ? "Skoða reikninga fyrir "
                : "Skoða reikninga fyrir"}
              <DropDown
                onChange={(e) => setSelectedProperty(e.currentTarget.value)}
              >
                <option value={""}>Velja húsfélag</option>
                {myProperties.map((propertyAddress) => {
                  return (
                    <option value={propertyAddress}>
                      {cleanPropertyAddress(propertyAddress)}
                    </option>
                  );
                })}
              </DropDown>
            </TableHeading>

            {invoices.length > 0 ? (
              <Table>
                <TableHeader>
                  <TableTr key="header-invoice">
                    <TableTh>Númer reiknings</TableTh>
                    <TableTh>Húsfélag</TableTh>
                    <TableTh>Upphæð</TableTh>
                    <TableTh className="hide-mobile">Eindagi</TableTh>
                    {/*
                            Disable until NAV is ready.
                        <TableTh className="hide-mobile">Greitt</TableTh>
                        */}
                  </TableTr>
                </TableHeader>
                <TableBody>
                  {invoices.map((invoice, index) => {
                    const dueDateTime = parseISO(invoice.dueDate);
                    const isDueDate = isBefore(dueDateTime, new Date());
                    const isPaid = invoice.remainingAmount === "0";
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const showErrorColorSchema = !isPaid && isDueDate;
                    /* Disable error colors temporarly */
                    return (
                      <TableTr error={false} key={index}>
                        <TableTh>
                          <Link
                            to={`/invoice/${toBase64(
                              `${invoice.invoiceNo}|${selectedProperty}`
                            )}`}
                          >
                            {invoice.invoiceNo}
                          </Link>
                        </TableTh>
                        <TableTh>{selectedProperty}</TableTh>
                        <TableTh>{formatAmount(invoice.amount)} kr.</TableTh>
                        <TableTh className="hide-mobile">
                          {format(parseISO(invoice.dueDate), "dd. MMMM yyyy", {
                            locale: isLocale,
                          })}
                        </TableTh>
                        {/*
                            Disable until NAV is ready.
                            <TableTh>{isPaid ? "Já" : "Nei"}</TableTh>*/}
                      </TableTr>
                    );
                  })}
                </TableBody>
              </Table>
            ) : selectedProperty ? (
              <EmptyWrapper>
                {loading ? (
                  <Loading loadingMessage="Leita af reikningum" />
                ) : (
                  <>
                    <AttentionIcon />
                    Enginn virkur reikningur fannst fyrir húsfélagið{" "}
                    {cleanPropertyAddress(selectedProperty)}
                  </>
                )}
              </EmptyWrapper>
            ) : (
              <EmptyWrapper>
                <WarningIcon />
                Ekkert húsfélag valið
              </EmptyWrapper>
            )}
          </InnerCard>
        </Container>
      </Page>
    </>
  );
};

export default InvoicesPage;
