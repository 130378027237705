/**
 * Gets an enumeration given a case-insensitive key. For a numeric enum this uses
 * its members' names; for a string enum this searches the specific string values.
 * Logs a warning if the letter case was ignored to find a match, and logs an error
 * including the supported values if no match was found.
 */
export function toEnumIgnoreCase<T>(
  target: T,
  caseInsentiveKey: string
): T[keyof T] {
  const needle = caseInsentiveKey.toLowerCase();

  // If the enum Object does not have a key "0", then assume a string enum
  const key = Object.keys(target).find(
    // @ts-ignore
    (k) => (target["0"] ? k : target[k]).toLowerCase() === needle
  );

  if (!key) {
    const expected = Object.keys(target)
      // @ts-ignore
      .map((k) => (target["0"] ? k : target[k]))
      .filter((k) => isNaN(Number.parseInt(k)))
      .join(", ");
    console.warn(`Could not map '${caseInsentiveKey}' to values ${expected}`);
    // @ts-ignore
    return undefined;
  }

  // @ts-ignore
  const name = target["0"] ? key : target[key];
  if (name !== caseInsentiveKey) {
    console.warn(`Ignored case to map ${caseInsentiveKey} to value ${name}`);
  }

  // @ts-ignore
  return target[key];
}
