import React, { useState } from "react";
import Modal from "react-responsive-modal";
import { useMutation } from "@apollo/client";
import {
  UpdateOwnerMutation,
  UpdateOwnerMutationVariables,
} from "../../../types";
import { updateOwner } from "../../queries/user";
import styled from "styled-components";
import { H1 } from "../layout/Typography";
import { emailValid } from "../utils/email";
import { BasicButton } from "../layout/Buttons";

type ChangeEmailModalProps = {
  open: boolean;
  onDone: () => void;
};

const InnerModal = styled.div`
  margin: 30px;
`;

const InputContainer = styled.div`
  text-align: right;
`;

const EmailInput = styled.input`
  height: 40px;
  width: 100%;
  display: block;
  border: none;
  background: transparent;
  font-size: 16px;
  transition: 0.3s ease;
  padding-top: 6px;
  padding-bottom: 0px;
  padding-left: 10px;
  outline: none;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  border-bottom: 2px solid #dedede;
  margin-bottom: 15px;
`;

export const ChangeEmailModal: React.FC<ChangeEmailModalProps> = ({
  open,
  onDone,
}) => {
  const [email, setEmail] = useState<string>("");

  const isValid = () => email !== "" && emailValid(email);

  // Mutation: Update Profile From API
  const [updateOwnerMutation] = useMutation<
    UpdateOwnerMutation,
    UpdateOwnerMutationVariables
  >(updateOwner, {
    onCompleted() {
      onDone();
    },
    onError(...error) {},
  });

  return (
    <Modal open={open} onClose={() => onDone()} center>
      <InnerModal>
        <H1>Vinsamlegast uppfærðu netfangið þitt.</H1>
        <p>
          Til þess að halda skráningu um þig í lagi, biðjum við þig að uppfæra
          netfangið þitt.
        </p>

        <InputContainer>
          <EmailInput
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            placeholder="netfangid@mitt.is"
          ></EmailInput>

          <BasicButton
            buttonType="primary"
            disabled={!isValid()}
            onClick={() => {
              updateOwnerMutation({
                variables: {
                  owner: {
                    email: email,
                  },
                },
              });
            }}
          >
            {!emailValid(email)
              ? "Ekki gilt netfang"
              : !isValid()
              ? "Engar breytingar til að vista"
              : "Vista breytingar og halda áfram"}
          </BasicButton>
        </InputContainer>
      </InnerModal>
    </Modal>
  );
};
