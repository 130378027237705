import { Flag } from "../../../flags";
import { UserType } from "../../../types";

export interface SystemState {
  readonly loggedIn: boolean;
  readonly loggingIn: boolean;
  readonly loggedInSocialNumber: string;
  readonly loggedInUserType?: UserType;
  readonly tick: number;
  readonly featureFlags: Flag[];
  readonly sessionError: boolean;
}

export enum SystemActionTypes {
  UPDATE_SESSION = "@@system/UPDATE_SESSION",
  CLEAR_SESSION = "@@system/CLEAR_SESSION",
  INCREMENENT_TICK = "@@system/INCR_TICK",
  SET_FEATURE_FLAG = "@@system/SET_FEATURE_FLAG",
}
