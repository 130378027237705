import { toEnumIgnoreCase } from "./conversion";

export enum DocumentType {
  Financial = "FINANCIAL",
  Meeting = "MEETING",
  Construction = "CONSTRUCTION",
  Unknown = ""
}

export const getKnownDocumentTypes = (): string[] => {
  return Object.keys(DocumentType).map(t => t.toUpperCase());
};

export const getKnownDocumentTypesOptions = (): {
  key: string;
  text: string;
  value: string;
}[] => {
  return getKnownDocumentTypes().map((type: string) => ({
    key: type,
    text: getHumanReadableType(type),
    value: type
  }));
};

export const interpretDocumentType = (documentType: string): DocumentType => {
  const translatedType = toEnumIgnoreCase(DocumentType, documentType);
  if (!translatedType) return DocumentType.Unknown;
  return translatedType;
};

export const getHumanReadableType = (documentType: string): string => {
  const docType = interpretDocumentType(documentType);
  switch (docType) {
    case DocumentType.Financial:
      return "Bókhald";
    case DocumentType.Meeting:
      return "Fundir";
    case DocumentType.Construction:
      return "Framkvæmdir";
    default:
      return "Óþekkt";
  }
};
