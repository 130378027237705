import { gql } from "@apollo/client";

export const getProperties = gql`
  query GetProperties {
    properties {
      id
      address
      externalId
      registrationId
      access
    }
  }
`;

export const getPropertiesForDashboard = gql`
  query GetPropertiesForDashboard {
    documentsV2(limit: 10, page: 0) {
      id
      name
      type
      mimetype
      description
      createdDate
      modifiedDate
      displayDate
    }
    properties {
      id
      address
      externalId
      registrationId
      access
      contacts {
        id
        name
        phone
        email
        role
        propertyId
      }
      documentsCount
      ownersCount
      propertySupportRequests {
        id
        summary
        assignee
        reporter
        date
        description
        finished
      }
      informations {
        key
        title
        value
        createdAt
        modifiedAt
      }
    }
    ownerConnections {
      properties {
        id
        address
        externalId
      }
    }
  }
`;

export const getPropertiesInvoicesForDashboard = gql`
  query GetPropertiesInvoicesForDashboard {
    properties {
      id
      address
      invoiceApprovals {
        approvalNo
        approvalStatus
      }
      invoices {
        invoiceNo
        socialNumber
        amount
        dueDate
        remainingAmount
      }
    }
    ownerConnections {
      properties {
        id
        address
        externalId
        invoices(take: 10) {
          invoiceNo
          socialNumber
          amount
          dueDate
          remainingAmount
        }
      }
    }
  }
`;

export const getPropertyFinancialsDetail = gql`
  query GetPropertyFinancialsDetail(
    $propertyId: String!
    $bankClaimsTake: Int
    $bankClaimsSkip: Int
    $bankClaimsDateFrom: String
    $bankClaimsDateTo: String
  ) {
    me {
      userId
      socialNumber
    }
    propertyById(id: $propertyId) {
      id
      address
      externalId
      registrationId
      access
      bankAccounts {
        bankAccountNo
        name
        contact
        id
        balanceAtDate
        bankAccountGroup
      }
      bankClaims(
        take: $bankClaimsTake
        skip: $bankClaimsSkip
        dateTo: $bankClaimsDateTo
        dateFrom: $bankClaimsDateFrom
      ) {
        no
        postingDate
        bankAccountNo
        description
        amount
        remainingAmount
        open
        entryType
      }
      invoiceApprovals {
        approvalNo
        creditInvoice
        invoiceToNo
        invoiceToName
        vendorNo
        vendorName
        date
        documentNo
        description
        reference
        deliveryDate
        totalAmount
        totalAmountWithVat
        lineTotalAmountWithVat
        approvalStatus
        approvalMessage
        approved
      }
    }
  }
`;

export const getPropertyDetail = gql`
  query GetPropertyDetail($propertyId: String!) {
    propertyById(id: $propertyId) {
      id
      address
      externalId
      registrationId
      access
      contacts {
        id
        name
        role
        phone
        email
        propertyId
      }
    }
  }
`;

export const assignOwnerToProperty = gql`
  mutation AssignOwnerToProperty($propertyId: String!, $ownerId: String!) {
    assignOwnerToProperty(propertyId: $propertyId, ownerId: $ownerId)
  }
`;

export const assignConnectedOwnerToProperty = gql`
  mutation AssignConnectedOwnerToProperty(
    $propertyId: String!
    $connectedOwnerId: String!
  ) {
    assignConnectedOwnerToProperty(
      propertyId: $propertyId
      connectedOwnerId: $connectedOwnerId
    )
  }
`;

export const unassignOwnerFromProperty = gql`
  mutation UnassignOwnerFromProperty($propertyId: String!, $ownerId: String!) {
    unassignOwnerFromProperty(propertyId: $propertyId, ownerId: $ownerId)
  }
`;

export const unassignConnectedOwnerFromProperty = gql`
  mutation UnassignConnectedOwnerFromProperty(
    $propertyId: String!
    $connectedOwnerId: String!
  ) {
    unassignConnectedOwnerFromProperty(
      propertyId: $propertyId
      connectedOwnerId: $connectedOwnerId
    )
  }
`;

export const createAndAssignOwnerToProperty = gql`
  mutation CreateAndAssignOwnerToProperty(
    $owner: OwnerAssignmentInput!
    $propertyId: String!
  ) {
    createAndAssignOwnerToProperty(propertyId: $propertyId, owner: $owner) {
      success
      ownerId
    }
  }
`;
