import * as React from "react";
import styled from "styled-components";

import { ErrorOutline } from "@styled-icons/material-twotone/ErrorOutline";

const ErrorIcon = styled(ErrorOutline)`
  height: 50px;
  color: #990000;
`;

const WrapperPage = styled.div`
  display: flex; /* establish flex container */
  flex-direction: column; /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center; /* center items horizontally, in this case */
  margin: auto;
  margin-top: 100px;
  width: 100%;
  height: 100%;
  .text {
    color: #757575;
    padding: 15px;
  }
`;

interface ErrorProps {
  className?: string;
  errorMessage?: string;
}

export const ErrorPage: React.SFC<ErrorProps> = ({
  className,
  errorMessage,
}) => (
  <WrapperPage className={className}>
    <ErrorIcon />
    {errorMessage && <div className="text">{errorMessage}</div>}
  </WrapperPage>
);
