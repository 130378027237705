import * as React from "react";
import isLocale from "date-fns/locale/is";
import { format, parseISO } from "date-fns";
import Header from "../layout/Header";
import { Page } from "../layout/Page";
import { useParams } from "react-router";
import { fromBase64 } from "../../../utils/base64";
import { useQuery } from "@apollo/client";
import {
  GetInvoiceLinesQuery,
  GetInvoiceLinesQueryVariables,
} from "../../../types";
import { getInvoiceLines } from "../../queries/invoices";
import { LoadingPage } from "../layout/Loading";
import styled from "styled-components";
import {
  Table,
  TableHeader,
  TableTr,
  TableTh,
  TableBody,
} from "../layout/Table";
import { formatAmount } from "../utils/amount";

const InvoiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 24px;
  color: #555;
`;

const InvoiceTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const InvoiceTitle = styled.h1`
  margin: 0;
  padding: 5px 0px 0px 10px;
  font-weight: 300;
  color: #000;
`;

const InvoiceSubTitle = styled.h3`
  margin: 0;
  padding: 5px 0px 0px 10px;
  font-weight: 300;
  font-size: 18px;
  color: #656464;
`;

const InvoiceSecTitle = styled.h3`
  margin: 0;
  padding: 5px 0px 0px 10px;
  font-weight: 300;
  font-size: 16px;
  color: #000;
`;

const InvoiceNoText = styled.h3`
  margin: 0;
  padding: 5px 0px 0px 10px;
  font-weight: 300;
  font-size: 16px;
  color: #000;
  b {
    font-size: 18px;
    font-weight: 600;
  }
`;

const InvoiceBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

export const InvoicePage = () => {
  let { invoiceRequest } = useParams<{ invoiceRequest: string }>();

  // parameter is base64 encoded string of "invoiceNo|propertyName"
  const decodedRequest = invoiceRequest && fromBase64(invoiceRequest);
  const invoiceRequestSplit =
    (decodedRequest && decodedRequest.split("|")) || [];
  const invoiceNo: string =
    invoiceRequestSplit.length > 0 ? invoiceRequestSplit[0] : "";
  const propertyName: string =
    invoiceRequestSplit.length > 1 ? invoiceRequestSplit[1] : "";

  const invoiceDetailsQuery = useQuery<
    GetInvoiceLinesQuery,
    GetInvoiceLinesQueryVariables
  >(getInvoiceLines, {
    variables: {
      propertyName: propertyName,
      invoiceNo: invoiceNo,
    },
  });
  const loading = invoiceDetailsQuery.loading;

  const invoiceInfo =
    invoiceDetailsQuery.data?.invoiceByPropertyNameAndInvoiceNo;
  const invoiceDetails = invoiceInfo?.details;

  const dueDateTime = parseISO(invoiceInfo?.dueDate || "");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isPaid = invoiceInfo?.remainingAmount === "0" || false;
  // Temporary disabled

  return (
    <>
      <Header />
      {loading || !invoiceInfo ? (
        <LoadingPage loadingMessage="Sæki upplýsingar um reikninginn þinn.." />
      ) : (
        <Page>
          <InvoiceWrapper>
            <InvoiceTitleWrapper>
              <div>
                <InvoiceTitle>Reikningur</InvoiceTitle>
                <InvoiceSubTitle>{propertyName}</InvoiceSubTitle>

                {(invoiceDetails?.description1 ||
                  invoiceDetails?.description2) && (
                  <InvoiceSecTitle>
                    Lýsing:
                    <br />
                    {invoiceDetails?.description1 &&
                      invoiceDetails?.description1}
                    <br />
                    {invoiceDetails?.description2 &&
                      invoiceDetails?.description2}
                  </InvoiceSecTitle>
                )}
              </div>
              <InvoiceNoText>
                Númer: <b>{invoiceInfo.invoiceNo}</b>
                <br /> Gjalddagi:{" "}
                <b>
                  {format(dueDateTime, "dd MMMM yyyy", {
                    locale: isLocale,
                  })}
                </b>
                <br />
                {/* 
                Temporary disable showing staða.
                Staða: <b>{isPaid ? "Greitt" : "Ekki greitt"}</b>
                */}
              </InvoiceNoText>
            </InvoiceTitleWrapper>
            <InvoiceBodyWrapper>
              <Table>
                <TableHeader>
                  <TableTr key="header-invoice">
                    <TableTh>
                      <b>Lýsing</b>
                    </TableTh>
                    <TableTh>
                      <b>Upphæð</b>
                    </TableTh>
                  </TableTr>
                </TableHeader>
                <TableBody>
                  {invoiceDetails &&
                    invoiceDetails.lines.map((line, i) => (
                      <TableTr key={i}>
                        <TableTh>{line.description}</TableTh>
                        <TableTh>{formatAmount(line.amount)} kr.</TableTh>
                      </TableTr>
                    ))}
                  <TableTr key="footer-invoice" lastRow>
                    <TableTh></TableTh>
                    <TableTh>{formatAmount(invoiceInfo.amount)} kr.</TableTh>
                  </TableTr>
                </TableBody>
              </Table>
            </InvoiceBodyWrapper>
          </InvoiceWrapper>
        </Page>
      )}
    </>
  );
};

export default InvoicePage;
