import * as React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1 1 auto; /* 1 */
  position: relative;
  min-width: 100px;
  max-width: 800px;
  margin: 20px auto;
  z-index: 1;
  width: 100%; /* 2 */

  h1.page {
    margin-left: 24px;
  }

  p.page {
    margin-left: 24px;
  }
`;

interface PageProps {
  className?: string;
}

export const Page: React.FC<PageProps> = ({ children }) => (
  <Wrapper>{children}</Wrapper>
);
