import * as React from "react";
import { Route, Redirect, RouteProps } from "react-router";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { LoadingPage } from "../layout/Loading";

interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  const { loggedIn, loggingIn, sessionError } = useSelector(
    (state: AppState) => state.system
  );

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        loggingIn ? (
          <LoadingPage
            loadingMessage={
              sessionError
                ? "Mistókst að auðkenna þig, reyni aftur..."
                : undefined
            }
          />
        ) : loggedIn ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
