import * as React from "react";
import Header from "../layout/Header";
import { DocumentsCard } from "../cards/DocumentsCard";
import { Page } from "../layout/Page";
import {
  CARD_KEY as PROFILE_CARD_KEY,
  ProfileCard,
} from "../cards/ProfileCard";
import ReactGA from "react-ga";
import {
  GetProfileInfoQuery,
  GetProfileInfoQueryVariables,
} from "../../../types";
import { getDashboardInfo } from "../../queries/dashboard";
import { PropertiesCard } from "../cards/PropertiesCard";
import { useEffect } from "react";
import { ChangeEmailModal } from "../modals/ChangeEmailModal";
import { InvoicesCard } from "../cards/InvoicesCard";
import { setCollapseCard } from "../../store/layout/actions";
import { useDispatch } from "react-redux";
import { useQuery } from "@apollo/client";

export const DashPage = () => {
  const dispatch = useDispatch();

  const [openChangeEmailModal, setOpenChangeEmailModal] = React.useState<
    boolean
  >(false);

  const dashboardProfileQuery = useQuery<
    GetProfileInfoQuery,
    GetProfileInfoQueryVariables
  >(getDashboardInfo);

  const me = dashboardProfileQuery.data?.me;

  useEffect(() => {
    if (me && !me.email) {
      // If not email collapse profile card and open set email modal.
      setOpenChangeEmailModal(true);
      dispatch(setCollapseCard(PROFILE_CARD_KEY, true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  return (
    <>
      <Header />
      <Page>
        <ProfileCard />
        <InvoicesCard />
        <DocumentsCard />
        <PropertiesCard />
      </Page>

      <ChangeEmailModal
        open={openChangeEmailModal}
        onDone={() => {
          ReactGA.event({
            category: "Profile",
            action: "Updated Profile",
          });
          setOpenChangeEmailModal(false);
          dashboardProfileQuery.refetch();
        }}
      />
    </>
  );
};

export default DashPage;
