import styled from "styled-components";
import { ChevronRight, ChevronDown } from "@styled-icons/evil";
import { BasicButton } from "../layout/Buttons";

export const CardActionButton = styled(BasicButton as any)`
  svg {
    margin-right: 8px;
  }
`;

export const CollapsedName = styled.div`
  p {
    margin: 0;
    margin-left: 12px;
    margin-top: 4px;
    font-size: 12px;
    color: #424242;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  @media (max-width: 480px) {
    h1 {
      font-size: 22px;
    }
  }
`;

export const TitleLeft = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TitleRight = styled.div`
  display: flex;
  align-self: center;
  svg {
    align-self: center;
  }
`;

export const CaretDownIcon = styled(ChevronDown)`
  color: #656464;
  height: 40px;
  align-self: flex-start;
`;

export const CaretRightIcon = styled(ChevronRight)`
  color: #656464;
  height: 40px;
  align-self: flex-start;
`;

export const SeperatorLine = styled.hr`
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 8px;
  margin-right: 8px;
  border: 1px solid #cecece;
`;
