import axios from "axios";

export const logout = () => {
  return axios.get("/auth/logout");
};

export const login = (phone: string) => {
  return axios.post("/auth/mobile/login", { number: phone });
};

export const status = (token: string, phone: string) => {
  return axios.post("/auth/mobile/login/status", { token, phone });
};
