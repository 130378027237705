import styled from "styled-components";

export const Breadcrumbs = styled.ul`
  list-style: none;
  padding: 0;
  margin-left: 24px;
  & > li:after {
    content: "/";
    padding: 0 8px;
  }
`;

export const Crumb = styled.li`
  display: inline-block;

  &:last-of-type:after {
    content: "";
    padding: 0;
  }

  a {
    color: #656464;
    text-decoration: none;
    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
`;
