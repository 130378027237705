import { gql } from "@apollo/client";

export const getInvoiceLines = gql`
  query GetInvoiceLines($propertyName: String!, $invoiceNo: String!) {
    invoiceByPropertyNameAndInvoiceNo(
      propertyName: $propertyName
      invoiceNo: $invoiceNo
    ) {
      invoiceNo
      amount
      dueDate
      remainingAmount
      details {
        description1
        description2
        totalAmount
        lines {
          amount
          description
        }
      }
    }
  }
`;

export const approveInvoiceLine = gql`
  mutation ApproveInvoiceLine(
    $propertyName: String!
    $socialNumber: String!
    $approve: Boolean!
    $invoiceNo: String!
    $rejectReason: String
  ) {
    approveInvoice(
      propertyName: $propertyName
      socialNumber: $socialNumber
      approve: $approve
      rejectReason: $rejectReason
      invoiceNo: $invoiceNo
    )
  }
`;

export const getAllowedProperties = gql`
  query GetAllowedProperties {
    properties {
      address
      id
    }
    connectedOwners {
      userId
      socialNumber
      name
      email
      phone
      connectedToProperties {
        id
        address
        externalId
      }
    }
  }
`;

export const getInvoiceByProperty = gql`
  # Write your query or mutation here
  query InvoicesByPropertyName($propertyName: String!, $take: Int!) {
    invoicesByPropertyName(propertyName: $propertyName, take: $take) {
      invoiceNo
      socialNumber
      amount
      dueDate
      remainingAmount
    }
  }
`;
