import { gql } from "@apollo/client";

export const getLoggedInUser = gql`
  query GetLoggedInUser {
    me {
      userId
      socialNumber
      name
      email
      phone
      userType
      session {
        expiration
      }
    }
  }
`;

export const updateOwner = gql`
  mutation UpdateOwner($owner: OwnerUpdateInput!) {
    updateOwner(owner: $owner)
  }
`;

export const sendRequestNotification = gql`
  mutation SendRequestNotification($input: RequestAccessNotificationInput!) {
    sendRequestAccessNotification(requestInput: $input)
  }
`;

export const ownersCardsForProperty = gql`
  query GetOwnerCardsByProperty($propertyId: String!) {
    getOwnerCardsByProperty(propertyId: $propertyId) {
      id
      name
      socialNumber
    }
    propertyById(id: $propertyId) {
      address
      access
    }
  }
`;

export const contactsForProperty = gql`
  query GetContactsForProperty($propertyId: String!) {
    propertyById(id: $propertyId) {
      address
      contacts {
        id
        name
        role
        email
        phone
        propertyId
      }
    }
  }
`;

export const deleteContactFromProperty = gql`
  mutation DeleteContactsFromProperty(
    $propertyId: String!
    $propertyContactId: String!
  ) {
    deletePropertyContact(
      propertyId: $propertyId
      propertyContactId: $propertyContactId
    )
  }
`;

export const updateContactForProperty = gql`
  mutation UpdateContactsForProperty(
    $propertyContactId: String!
    $propertyContact: PropertyContactInput!
  ) {
    updatePropertyContact(
      propertyContactId: $propertyContactId
      propertyContact: $propertyContact
    ) {
      id
      name
      role
      phone
      email
      propertyId
    }
  }
`;

export const createContactForProperty = gql`
  mutation CreateContactsForProperty($propertyContact: PropertyContactInput!) {
    createPropertyContact(propertyContact: $propertyContact) {
      id
      name
      role
      phone
      email
      propertyId
    }
  }
`;

export const ownerCardBySocialNumber = gql`
  query GetOwnerCardBySocialNumber($socialNumber: String!) {
    getOwnerCardBySocialNumber(socialNumber: $socialNumber) {
      id
      name
      socialNumber
    }
  }
`;

export const disconnectOwner = gql`
  mutation DisconnectOwner($id: String!) {
    disconnectConnectedOwner(ownerId: $id)
  }
`;

export const connectOwner = gql`
  mutation ConnectOwner($id: String!, $reason: String) {
    connectOwner(ownerId: $id, reason: $reason)
  }
`;

export const createdAndConnectOwner = gql`
  mutation CreateAndConnectOwner(
    $input: OwnerAssignmentInput!
    $reason: String
  ) {
    createAndConnectOwner(owner: $input, reason: $reason) {
      success
      ownerId
    }
  }
`;
