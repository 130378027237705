import React, { useEffect } from "react";
import Modal from "react-responsive-modal";

import styled from "styled-components";
import { H1 } from "../layout/Typography";
import {
  CreateContactsForPropertyMutation,
  CreateContactsForPropertyMutationVariables,
  PropertyContact,
  UpdateContactsForPropertyMutation,
  UpdateContactsForPropertyMutationVariables,
} from "../../../types";
import { BasicButton } from "../layout/Buttons";
import { emailValid } from "../utils/email";
import { useMutation } from "@apollo/client";
import {
  createContactForProperty,
  updateContactForProperty,
} from "../../queries/user";

type PropertyManageContactModalProps = {
  open: boolean;
  onDone: () => void;
  propertyName: string;
  propertyId: string;
  propertyContact?: PropertyContact;
};

const InnerModal = styled.div`
  margin: 30px;
  @media (max-width: 480px) {
    margin: 8px;
  }
`;

const InputContainer = styled.div`
  margin-top: 24px;
  text-align: left;
  button {
    text-align: right;
  }
`;

const Input = styled.input<{ showError: boolean }>`
  height: 40px;
  width: 100%;
  display: block;
  border: none;
  border: ${(props) => (props.showError ? "1px solid red" : "none")};
  background: transparent;
  font-size: 16px;
  transition: 0.3s ease;
  padding-top: 6px;
  padding-bottom: 0px;
  padding-left: 10px;
  outline: none;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  border-bottom: 2px solid ${(props) => (props.showError ? "red" : "#dedede")};
  margin-bottom: 15px;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  margin: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`;

export const PropertyManageContactModal: React.FC<PropertyManageContactModalProps> = ({
  open,
  onDone,
  propertyContact,
  propertyName,
  propertyId,
}) => {
  const [updateContactMutation] = useMutation<
    UpdateContactsForPropertyMutation,
    UpdateContactsForPropertyMutationVariables
  >(updateContactForProperty);

  const [createContactMutation] = useMutation<
    CreateContactsForPropertyMutation,
    CreateContactsForPropertyMutationVariables
  >(createContactForProperty);

  const [email, setEmail] = React.useState<string | undefined>();
  const [name, setName] = React.useState<string | undefined>();
  const [role, setRole] = React.useState<string | undefined>();
  const [phone, setPhone] = React.useState<string | undefined>();

  useEffect(() => {
    setEmail(propertyContact?.email || undefined);
    setName(propertyContact?.name || undefined);
    setRole(propertyContact?.role || undefined);
    setPhone(propertyContact?.phone || undefined);
  }, [propertyContact]);

  const isValid = () => email !== "" && emailValid(email) && !!name && !!role;
  const isChanged = () =>
    email !== propertyContact?.email ||
    name !== propertyContact?.name ||
    role !== propertyContact?.role ||
    phone !== propertyContact?.phone;

  return (
    <Modal open={open} onClose={() => onDone()} center>
      <InnerModal>
        {propertyContact ? (
          <H1>Breyta tengilið {propertyContact.name}</H1>
        ) : (
          <H1>Bæta við nýjum tengilið á húsfélagið {propertyName}</H1>
        )}

        <InputContainer>
          <Label htmlFor="name">Nafn</Label>
          <Input
            id="name"
            showError={name === ""}
            value={name || ""}
            onChange={(e) => setName(e.currentTarget.value)}
            placeholder="Jón Jónsson"
          ></Input>
          <Label htmlFor="email">Tölvupóstur</Label>
          <Input
            id="email"
            showError={
              email === "" || (email !== undefined && !emailValid(email))
            }
            value={email || ""}
            onChange={(e) => setEmail(e.currentTarget.value)}
            placeholder="netfangid@simnet.is"
          ></Input>
          <Label htmlFor="phone">Símanúmer</Label>
          <Input
            id="phone"
            showError={false}
            value={phone || ""}
            onChange={(e) => setPhone(e.currentTarget.value)}
            placeholder="8991234"
          ></Input>
          <Label htmlFor="role">Hlutverk</Label>
          <Input
            id="role"
            showError={role === ""}
            value={role || ""}
            onChange={(e) => setRole(e.currentTarget.value)}
            placeholder="Formaður"
          ></Input>
          <ButtonContainer>
            {" "}
            <BasicButton
              buttonType="primary"
              disabled={!isValid() || !isChanged()}
              onClick={() => {
                propertyContact
                  ? updateContactMutation({
                      variables: {
                        propertyContact: {
                          name: name || "",
                          email: email || "",
                          propertyId,
                          role: role || "",
                          phone: phone || "",
                        },
                        propertyContactId: propertyContact?.id || "",
                      },
                    }).then(() => {
                      onDone();
                    })
                  : createContactMutation({
                      variables: {
                        propertyContact: {
                          name: name || "",
                          email: email || "",
                          role: role || "",
                          phone: phone || "",
                          propertyId,
                        },
                      },
                    }).then(() => {
                      onDone();
                    });
              }}
            >
              {!isChanged()
                ? "Engar breytingar til að vista"
                : !emailValid(email)
                ? "Ekki gilt netfang"
                : !isValid()
                ? "Ekki nægjanlegar upplýsingar"
                : propertyContact
                ? "Vista breytingar og halda áfram"
                : "Bæta við tengilið og halda áfram"}
            </BasicButton>
          </ButtonContainer>
        </InputContainer>
      </InnerModal>
    </Modal>
  );
};
