import * as React from "react";
import Header from "../layout/Header";
import { Page } from "../layout/Page";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import {
  GetPropertyFinancialsDetailQuery,
  GetPropertyFinancialsDetailQueryVariables,
} from "../../../types";
import { LoadingPage } from "../layout/Loading";
import { getPropertyFinancialsDetail } from "../../queries/properties";
import { ErrorPage } from "../layout/Error";
import styled from "styled-components";
import { PropertyBankAccountsCard } from "../cards/PropertyBankAccountsCard";
import { PropertyClaimsCard } from "../cards/PropertyClaimsCard";
import { PropertyInvoiceApprovalCard } from "../cards/PropertyInvoiceApprovalCard";
import { CardContainer, InnerCard } from "../layout/CardCommon";
import { TableHeading } from "../layout/Table";
import { Breadcrumbs, Crumb } from "../layout/BreadCrumbs";

const WrapperPropertyDetails = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: 35px;
`;

const BodyWrapper = styled.div`
  width: 100%;
`;

export const PropertyFinancialsPage = () => {
  let { propertyId } = useParams<{ propertyId: string }>();

  const propertyFinancialsDetailQuery = useQuery<
    GetPropertyFinancialsDetailQuery,
    GetPropertyFinancialsDetailQueryVariables
  >(getPropertyFinancialsDetail, {
    variables: {
      propertyId: propertyId,
      bankClaimsSkip: 0,
    },
  });

  const loading = propertyFinancialsDetailQuery.loading;
  const property = propertyFinancialsDetailQuery.data?.propertyById;
  const me = propertyFinancialsDetailQuery.data?.me;

  return (
    <>
      <Header />
      {loading && (property?.bankClaims || []).length < 1 ? (
        <LoadingPage loadingMessage="Sæki upplýsingar um húsfélagið þitt.." />
      ) : (
        <Page>
          {property ? (
            <>
              <h1 className="page">Fjárhagsupplýsingar</h1>
              <Breadcrumbs>
                <Crumb>
                  <a href="/">Heim</a>
                </Crumb>
                <Crumb>
                  <a href="/">Fjárhagsupplýsingar</a>
                </Crumb>
                {propertyId && (
                  <Crumb>
                    <a href={`/property/${propertyId}/management`}>
                      {
                        propertyFinancialsDetailQuery?.data?.propertyById
                          .address
                      }
                    </a>
                  </Crumb>
                )}
              </Breadcrumbs>
              <CardContainer>
                <InnerCard>
                  <TableHeading>
                    <h2>Upplýsingar um húsfélag</h2>
                    <p>
                      <b>Nafn</b>: {property.address}
                    </p>
                    {property.registrationId && (
                      <p>
                        <b>Kennitala</b>: {property.registrationId}
                      </p>
                    )}
                    <p>
                      <b>Aðgangsheimild </b>:{" "}
                      {property.access === "full"
                        ? "Fullur aðgangur"
                        : "Takmarkaður aðgangur"}
                    </p>
                  </TableHeading>
                </InnerCard>
              </CardContainer>
              <WrapperPropertyDetails>
                <BodyWrapper>
                  <div className="row top-xs">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <PropertyInvoiceApprovalCard
                        propertyName={property.address}
                        invoiceApprovals={property.invoiceApprovals || []}
                        socialNumber={me?.socialNumber || ""}
                        refetch={() => propertyFinancialsDetailQuery.refetch()}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <PropertyBankAccountsCard
                        bankAccounts={property.bankAccounts || undefined}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <PropertyClaimsCard
                        loading={propertyFinancialsDetailQuery.loading}
                        claims={property.bankClaims || []}
                        fetchMore={() => {
                          propertyFinancialsDetailQuery.fetchMore({
                            variables: {
                              propertyId: propertyId,
                              bankClaimsSkip: property.bankClaims?.length || 0,
                            },
                            updateQuery: (
                              prev: GetPropertyFinancialsDetailQuery,
                              { fetchMoreResult }
                            ) => {
                              if (!fetchMoreResult) return prev;
                              return Object.assign({}, prev, {
                                propertyById: {
                                  ...prev.propertyById,
                                  bankClaims: [
                                    ...(prev.propertyById?.bankClaims || []),
                                    ...(fetchMoreResult.propertyById
                                      ?.bankClaims || []),
                                  ],
                                },
                              });
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </BodyWrapper>
              </WrapperPropertyDetails>
            </>
          ) : (
            <ErrorPage errorMessage="Villa við að sækja upplýsingar um húsfélag." />
          )}
        </Page>
      )}
    </>
  );
};

export default PropertyFinancialsPage;
