import { SystemState, SystemActionTypes } from "./types";
import { Reducer } from "redux";
import { defaultFlags } from "../../../flags";
import { AppState } from "..";

const initialState: SystemState = {
  loggedIn: false,
  loggingIn: true,
  loggedInSocialNumber: "",
  featureFlags: defaultFlags,
  tick: 0,
  sessionError: false,
};

const reducer: Reducer<SystemState> = (state = initialState, action) => {
  switch (action.type) {
    case SystemActionTypes.UPDATE_SESSION: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SystemActionTypes.CLEAR_SESSION: {
      return {
        loggedIn: false,
        loggedInSocialNumber: "",
      };
    }
    case SystemActionTypes.INCREMENENT_TICK: {
      return {
        ...state,
        tick: state.tick + 1,
      };
    }
    case SystemActionTypes.SET_FEATURE_FLAG: {
      return {
        ...state,
        featureFlags: [...state.featureFlags, action.payload],
      };
    }
    default:
      return state;
  }
};

export const selectFeatureFlags = (state: AppState) =>
  state.system.featureFlags;

export { reducer as systemReducer };
