import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { configureStore } from "./app/store";
import { Router } from "react-router";
import "./reset.css";
import "./index.css";
import "./button.css";
import "./assets/flexboxgrid.min.css";
import App from "./app";
import * as serviceWorker from "./serviceWorker";
import { InMemoryCache } from "@apollo/client/cache";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import queryString from "query-string";
import { toast } from "react-toastify";

const parsedQueryParams = queryString.parse(window.location.search);

// prepare store
const history = createBrowserHistory();
const store = configureStore();

const link = createHttpLink({
  uri: "/graphql",
  credentials: "same-origin",
});

const linkPreviewToken = createHttpLink({
  uri: "/graphql",
  headers: {
    Authorization: "Bearer " + parsedQueryParams.previewToken,
  },
});

const getLink = (): ApolloLink => {
  if (parsedQueryParams.previewToken) {
    console.warn("Using Preview Token.");
    toast.warn(
      "Þú ert að nota auðkenningu fyrir annan íbúa, sem endist mjög stutt (5 mín)."
    );
    return linkPreviewToken;
  }
  return link;
};

const client = new ApolloClient({
  cache: new InMemoryCache(),
  name: "minn-eignarekstur-web",
  version: "1.0",
  link: getLink(),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
