import React from "react";
import styled from "styled-components";
import { CashCoin } from "@styled-icons/bootstrap";
import { BankClaim } from "../../../types";
import {
  Table,
  TableHeader,
  TableTr,
  TableTh,
  TableBody,
} from "../layout/Table";
import { parseISO, format } from "date-fns";
import isLocale from "date-fns/locale/is";
import { formatAmount } from "../utils/amount";
import { ClassicSpinner } from "react-spinners-kit";
import { BasicButton } from "../layout/Buttons";

const Container = styled.div`
  word-wrap: break-word;
  white-space: normal;
  padding: 15px;
  width: 100%;
`;

const InnerCard = styled.div`
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  border-bottom: 2px solid #dedede;
  background-color: #ffffff;
  padding: 15px;
`;

const MoreButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ClaimsIcon = styled(CashCoin as any)`
  color: #656464;
  height: 20px;
  align-self: flex-start;
  margin-right: 8px;
  padding-bottom: 4px;
`;

const Title = styled.h3`
  text-transform: uppercase;
  font-size: 16px;
  color: #656464;
  padding-top: 4px;
  margin-top: 0px;
  z-index: 1;
  position: relative;
  :before {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    content: attr(title);
    background-color: #fff;
    color: #fff;
    z-index: -1;
    padding-right: 0.5em;
  }
  ::after {
    position: absolute;
    content: "";
    display: block;
    left: 0;
    right: 0;
    top: 50%;
    background-color: #e6e6e6;
    height: 1px;
    z-index: -2;
  }
`;

type PropertyClaimsCardProps = {
  claims: BankClaim[];
  fetchMore: () => void;
  loading?: boolean;
};
export const PropertyClaimsCard: React.FC<PropertyClaimsCardProps> = (
  props
) => {
  return (
    <Container>
      <InnerCard>
        <Title title="Bankakröfur........">
          <ClaimsIcon />
          Bankfærslur
        </Title>

        {props.claims && props.claims.length > 0 ? (
          <>
            <Table>
              <TableHeader>
                <TableTr key="header-invoice">
                  <TableTh className="hide-mobile">Númer</TableTh>
                  <TableTh className="hide-mobile">Bankareikningur</TableTh>
                  <TableTh>Upphæð</TableTh>
                  <TableTh className="hide-mobile">Dagsetning</TableTh>
                  <TableTh className="hide-mobile">Útskýring</TableTh>
                  <TableTh className="show-mobile">Útskýring</TableTh>
                </TableTr>
              </TableHeader>
              <TableBody>
                {props.claims.map((claim, index) => {
                  const postingDate = parseISO(claim.postingDate);
                  return (
                    <TableTr key={index}>
                      <TableTh className="hide-mobile">{claim.no}</TableTh>
                      <TableTh className="hide-mobile center">
                        {parseInt(claim.bankAccountNo, 10)}
                      </TableTh>
                      <TableTh>{formatAmount(claim.amount)} kr.</TableTh>
                      <TableTh className="hide-mobile">
                        {format(postingDate, "d MMMM yyyy", {
                          locale: isLocale,
                        })}
                      </TableTh>
                      <TableTh className="hide-mobile">
                        {claim.description}
                      </TableTh>
                      <TableTh className="show-mobile">
                        <p>
                          <b>Dags:</b>{" "}
                          {format(postingDate, "d MMMM yyyy", {
                            locale: isLocale,
                          })}
                          <br /> <b>Reikningsnúmer:</b>{" "}
                          {parseInt(claim.bankAccountNo, 10)}
                        </p>
                        <p> {claim.description}</p>
                      </TableTh>
                    </TableTr>
                  );
                })}
              </TableBody>
            </Table>
            <MoreButtonWrapper>
              <BasicButton
                buttonType="primary"
                onClick={() => props.fetchMore()}
              >
                <ClassicSpinner
                  color="#fff"
                  size={15}
                  loading={props.loading}
                />{" "}
                {props.loading ? "Sæki fleiri færslur" : "Sækja fleiri færslur"}
              </BasicButton>
            </MoreButtonWrapper>
          </>
        ) : (
          <div>Enginn krafa fannst fyrir húsfélag.</div>
        )}
      </InnerCard>
    </Container>
  );
};
