import React from "react";
import Modal from "react-responsive-modal";
import {
  Table,
  TableHeader,
  TableTr,
  TableTh,
  TableBody,
} from "../layout/Table";

import styled from "styled-components";
import { H1 } from "../layout/Typography";
import { PropertySupportRequest } from "../../../types";
import { format, parseISO } from "date-fns";
import isLocale from "date-fns/locale/is";
import { BasicButton } from "../layout/Buttons";

type PropertySupportRequestModalProps = {
  open: boolean;
  onDone: () => void;
  requests: PropertySupportRequest[];
  propertyName: string;
};

const InnerModal = styled.div`
  margin: 30px;
`;

const TableContainer = styled.div`
  padding-top: 16px;
  margin-bottom: 24px;
`;

const Message = styled.div`
  padding: 16px;
  text-align: center;
`;

export const PropertySupportRequestModal: React.FC<PropertySupportRequestModalProps> = ({
  open,
  onDone,
  propertyName,
  requests,
}) => {
  return (
    <Modal open={open} onClose={() => onDone()} center>
      <InnerModal>
        <H1>Beiðnir fyrir {propertyName}</H1>
        <TableContainer>
          {requests.length > 0 ? (
            <>
              <Table>
                <TableHeader>
                  <TableTr key="header-support-request">
                    <TableTh>Titill</TableTh>
                    <TableTh>Sent inn af</TableTh>
                    <TableTh>Dagsett</TableTh>
                    <TableTh>Klárað</TableTh>
                  </TableTr>
                </TableHeader>
                <TableBody>
                  {requests.map((request, index) => {
                    const dateCreated = format(
                      parseISO(request.date),
                      "dd. MMMM yyyy",
                      {
                        locale: isLocale,
                      }
                    );

                    return (
                      <TableTr key={index}>
                        <TableTh>{request.summary}</TableTh>
                        <TableTh>{request.reporter}</TableTh>
                        <TableTh>{dateCreated}</TableTh>
                        <TableTh>{request.finished ? "Já" : "Nei"}</TableTh>
                      </TableTr>
                    );
                  })}
                </TableBody>
              </Table>
            </>
          ) : (
            <Message>Engar beiðnir til fyrir húsfélagið.</Message>
          )}
        </TableContainer>
        <BasicButton buttonType="primary" onClick={() => onDone()}>
          Loka
        </BasicButton>
      </InnerModal>
    </Modal>
  );
};
