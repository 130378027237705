import React from "react";
import styled from "styled-components";
import Modal from "react-responsive-modal";
import { Error } from "@styled-icons/boxicons-regular";

type LoginErrorModalProps = {
  open: boolean;
  onDone: () => void;
  message: string;
  title: string;
};

const MessageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  max-width: 384px;
  align-items: center;

  div > p {
    margin-left: 16px;
  }
`;

const InnerModal = styled.div`
  margin: 30px;
`;

export const LoginErrorModal: React.FC<LoginErrorModalProps> = ({
  open,
  onDone,
  title,
  message,
}) => {
  return (
    <Modal open={open} onClose={() => onDone()} center>
      <InnerModal>
        <MessageContainer>
          <div>{<Error color="#c74949" size={50} />}</div>
          <div>
            <p>{message}</p>
          </div>
        </MessageContainer>
      </InnerModal>
    </Modal>
  );
};
