import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { User } from "@styled-icons/evil";
import { Link } from "@styled-icons/material-rounded";
import {
  AddCircle,
  RemoveCircle,
  CheckmarkCircle,
  AlertCircle,
  CheckmarkDoneCircle,
} from "@styled-icons/ionicons-outline";
import { H1 } from "../layout/Typography";
import {
  CaretDownIcon,
  CaretRightIcon,
  TitleContainer,
  TitleLeft,
  TitleRight,
} from "./CardCommon";
import { Loading } from "../layout/Loading";
import { useMutation, useQuery } from "@apollo/client";
import { disconnectOwner, updateOwner } from "../../queries/user";
import {
  AssignConnectedOwnerToPropertyMutation,
  AssignConnectedOwnerToPropertyMutationVariables,
  DisconnectOwnerMutation,
  DisconnectOwnerMutationVariables,
  GetProfileInfoQuery,
  GetProfileInfoQueryVariables,
  UnassignConnectedOwnerFromPropertyMutation,
  UnassignConnectedOwnerFromPropertyMutationVariables,
  UpdateOwnerMutation,
  UpdateOwnerMutationVariables,
} from "../../../types";
import { emailValid } from "../utils/email";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { setCollapseCard } from "../../store/layout/actions";
import { BasicButton } from "../layout/Buttons";
import { CheckboxToggle } from "react-rainbow-components";
import { getDashboardInfo } from "../../queries/dashboard";
import { ConnectOwnenModal } from "../modals/ConnectOwnenModal";
import {
  assignConnectedOwnerToProperty,
  unassignConnectedOwnerFromProperty,
} from "../../queries/properties";
import { toast } from "react-toastify";
import { Tooltip } from "../layout/Tooltip";

const LoadingCard = styled(Loading)`
  height: 200px;
`;

const ValidCircleIcon = styled(CheckmarkCircle)`
  height: 24px;
`;

const DoneCircleIcon = styled(CheckmarkDoneCircle)`
  height: 24px;
`;

const InvalidCircleIcon = styled(AlertCircle)`
  height: 24px;
`;

const AddCircleIcon = styled(AddCircle)`
  height: 24px;
`;

const RemoveCircleIcon = styled(RemoveCircle)`
  height: 24px;
`;

const UserIcon = styled(User)`
  color: #cecece;
  height: 52px;
`;

const ProfileSaveButton = styled(BasicButton as any)`
  svg {
    margin-left: 4px;
  }
`;

const Container = styled.div`
  word-wrap: break-word;
  white-space: normal;
  padding: 15px;
`;

const ConnectedOwnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-top: 16px;
  justify-content: space-between;
  justify-items: center;
  @media (max-width: 480px) {
    h1 {
      font-size: 22px;
    }
  }
`;

const ConnectedOwnersList = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 16px;

  ul {
    list-style-type: none;
    color: #656464;
    margin: 4px;
    padding: 0px;
  }

  ul span {
    color: #000;
  }
`;

const TipsMesage = styled.div`
  display: flex;
  justify-content: flex-end;
  color: #162a3b;
  margin: 4px;
  margin-top: 8px;
  font-style: normal;
  font-size: 12px;
`;

const InnerCard = styled.div`
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  border-bottom: 2px solid #dedede;
  background-color: #ffffff;
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

const InnerCardSectionTitle = styled.div`
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 14px;
  margin-bottom: 8px;
  h2 {
    color: #656464;
    font-size: 14px;
    font-style: bold;
    text-transform: uppercase;
    margin: 0px;
  }
  span {
    font-size: 12px;
    color: #5a5a5a;
  }
`;

const ConnectedListContainer = styled.div`
  margin: 16px;
  margin-top: 8px;
  color: #656464;
  li {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    span {
      align-self: center;
    }
    p {
      margin: 4px;
    }
    margin-bottom: 8px;
  }
`;

const Form = styled.div`
  padding: 24px;
  padding-top: 0px;
`;

type FormGroupProps = { valid?: boolean; noValidation?: boolean };
const FormGroup = styled.div`
  position: relative;
  margin-top: 25px;
  label {
    top: 20px;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: 0.2s;
  }
  border-bottom: 1px solid
    ${(props: FormGroupProps) =>
      props.noValidation ? "gray" : props.valid ? "green" : "red"};
  input:focus + label,
  input:disabled + label,
  input:valid + label {
    top: 100%;
    margin-top: -55px;
    height: 30px;
    color: gray;
  }
`;
const FormLabel = styled.label`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: 0.2s;
  color: gray;
`;
const FormInput = styled.input`
  height: 40px;
  width: 100%;
  display: block;
  border: none;
  background: transparent;
  font-size: 16px;
  transition: 0.3s ease;
  padding-top: 6px;
  padding-bottom: 0px;
  outline: none;
`;

const CollapsedName = styled.div`
  p {
    margin: 0;
    margin-left: 12px;
    font-size: 12px;
    color: #424242;
  }
`;

const PlugDisconnectedIcon = styled(Link)`
  color: #656464;
  height: 24px;
  margin-right: 4px;
  margin-bottom: 4px;
`;

export type ProfileCardProps = {};

export const CARD_KEY = "PROFILE_CARD";

export const ProfileCard: React.FC<ProfileCardProps> = (props) => {
  const dispatch = useDispatch();

  const dashboardProfileQuery = useQuery<
    GetProfileInfoQuery,
    GetProfileInfoQueryVariables
  >(getDashboardInfo);

  const loading = dashboardProfileQuery.loading;
  const me = dashboardProfileQuery.data?.me;
  const connectedOwners = dashboardProfileQuery.data?.connectedOwners || [];
  const connections = dashboardProfileQuery.data?.ownerConnections || [];
  const myProperties = dashboardProfileQuery.data?.properties || [];

  /* COLLAPSE */
  const { collapsed_card } = useSelector((state: AppState) => state.layout);
  const DEFAULT_COLLAPSED = false;
  const CARD_COLLAPSED =
    collapsed_card[CARD_KEY] === undefined
      ? DEFAULT_COLLAPSED
      : collapsed_card[CARD_KEY];

  // Define data
  const name = me?.name;
  const socialNumber = me?.socialNumber;
  const email = me?.email;
  const phone = me?.phone;

  // Local state
  const [emailLocal, setEmailLocal] = useState<string>();
  const [phoneLocal, setPhoneLocal] = useState<string>();
  const [showConnectModal, setShowConnectModal] = useState(false);

  /**
   * Is valid - to submit changes.
   */
  const isValid = () => {
    const valid =
      emailLocal !== "" &&
      emailLocal !== undefined &&
      emailValid(emailLocal) &&
      (phone !== phoneLocal || email !== emailLocal);
    return valid;
  };

  // Mutation: Update Profile From API
  const [updateOwnerMutation] = useMutation<
    UpdateOwnerMutation,
    UpdateOwnerMutationVariables
  >(updateOwner, {
    onCompleted(_data) {
      dashboardProfileQuery.refetch();
    },
    onError(...error) {},
  });

  // Mutation: Disconnect user
  const [disconnectOwnerMutation] = useMutation<
    DisconnectOwnerMutation,
    DisconnectOwnerMutationVariables
  >(disconnectOwner, {
    onCompleted(_data) {
      dashboardProfileQuery.refetch();
    },
    onError(...error) {
      console.error(`Error: ${error}`);
      toast.error(
        "Villa kom upp við að aftengja íbúa. Er möguleiki að íbúi sé tengdur við húsfélag ennþá?"
      );
    },
  });

  const [assignConnectedOwnerToPropertyMutation] = useMutation<
    AssignConnectedOwnerToPropertyMutation,
    AssignConnectedOwnerToPropertyMutationVariables
  >(assignConnectedOwnerToProperty, {
    onCompleted(_data) {
      dashboardProfileQuery.refetch();
    },
    onError(...error) {
      console.error(`${error}`);
      toast.error("Villa kom upp við að gefa íbúa aðgang.");
    },
  });

  const [unassignConnectedOwnerFromPropertyMutation] = useMutation<
    UnassignConnectedOwnerFromPropertyMutation,
    UnassignConnectedOwnerFromPropertyMutationVariables
  >(unassignConnectedOwnerFromProperty, {
    onCompleted(_data) {
      dashboardProfileQuery.refetch();
    },
    onError(...error) {
      console.error(`${error}`);
      toast.error("Villa kom upp");
    },
  });

  useEffect(() => {
    setEmailLocal(email || "");
    setPhoneLocal(phone || "");
  }, [email, phone]);

  return (
    <>
      <ConnectOwnenModal
        ownerId={me?.userId!}
        open={showConnectModal}
        onDone={() => {
          dashboardProfileQuery.refetch();
          setShowConnectModal(false);
        }}
      />

      <Container>
        <InnerCard>
          {!loading ? (
            CARD_COLLAPSED ? (
              <>
                {/* Non-Collapsed Section, Showing user settings */}
                <TitleContainer>
                  <TitleLeft>
                    <UserIcon />
                    <CollapsedName>
                      <H1>Notendastillingar</H1>
                      <p>{name}</p>
                    </CollapsedName>
                  </TitleLeft>
                  <TitleRight>
                    <Tooltip tooltipMessage="Loka notendastillingum">
                      <CaretDownIcon
                        aria-label="Loka"
                        onClick={() =>
                          dispatch(setCollapseCard(CARD_KEY, false))
                        }
                      />
                    </Tooltip>
                  </TitleRight>
                </TitleContainer>

                {/*<MessageContainer>
                  <ExclamationIcon />
                  <div>
                    Athugið að þú ert innskráð(ur) í{" "}
                    {expiration &&
                      formatDistanceToNow(parseISO(expiration), {
                        locale: isLocale,
                      })}{" "}
                    í viðbót
                  </div>
                    </MessageContainer>*/}

                <InnerCardSectionTitle>
                  <h2>Prófíll</h2>
                  <span>Almennar upplýsingar um innskráðan íbúa</span>
                </InnerCardSectionTitle>

                <Form>
                  <FormGroup noValidation>
                    <FormInput required disabled id="name" value={name || ""} />
                    <FormLabel htmlFor="name">Nafn</FormLabel>
                  </FormGroup>

                  <FormGroup noValidation>
                    <FormInput
                      required
                      disabled
                      id="socialnumber"
                      value={socialNumber || ""}
                    />
                    <FormLabel htmlFor="socialnumber">Kennitala</FormLabel>
                  </FormGroup>

                  <FormGroup
                    valid={emailValid(emailLocal) && emailLocal !== ""}
                  >
                    <FormInput
                      required
                      id="email"
                      value={emailLocal || ""}
                      onChange={(e) => setEmailLocal(e.currentTarget.value)}
                    />
                    <FormLabel htmlFor="email">Netfang</FormLabel>
                  </FormGroup>

                  <FormGroup noValidation>
                    <FormInput
                      id="phone"
                      value={phoneLocal || ""}
                      onChange={(e) => setPhoneLocal(e.currentTarget.value)}
                    />
                    <FormLabel htmlFor="phone">Símanúmer</FormLabel>
                  </FormGroup>
                </Form>
                <ProfileSaveButton
                  buttonType="primary"
                  fluid
                  disabled={!isValid()}
                  onClick={() => {
                    updateOwnerMutation({
                      variables: {
                        owner: {
                          email: emailLocal || "",
                          phone: phoneLocal || null,
                        },
                      },
                    });
                  }}
                >
                  {!emailValid(emailLocal) ? (
                    <>
                      Ógildur prófill <InvalidCircleIcon />
                    </>
                  ) : !isValid() ? (
                    <>
                      Engin breyting til að vista <DoneCircleIcon />
                    </>
                  ) : (
                    <>
                      Vista breytingar <ValidCircleIcon />
                    </>
                  )}
                </ProfileSaveButton>

                <br />

                <InnerCardSectionTitle>
                  <h2>Tengdir íbúar</h2>
                  <span>
                    Hægt er að gefa tengdum íbúum aðgang að upplýsingum um
                    húsfélög, skjöl og reikninga.
                  </span>
                </InnerCardSectionTitle>

                <ConnectedListContainer>
                  {connectedOwners.length > 0 ? (
                    connectedOwners.map((owner) => (
                      <li key={owner.userId!}>
                        <div>
                          <InnerCardSectionTitle>
                            <h2>
                              <b>{owner.name}</b> ({owner.socialNumber})
                            </h2>
                            <span>
                              Vinsamlegast veldu húsfélög sem tengdur íbúi á að
                              hafa aðgang að: <br />
                            </span>
                          </InnerCardSectionTitle>

                          <ul>
                            {myProperties
                              .filter((p) => {
                                const isConnection = connections
                                  .flatMap((x) => x.properties)
                                  .find((x) => x.id === p.id);

                                return !isConnection;
                              })
                              .map((property) => (
                                <li key={property.id + "k"}>
                                  <CheckboxToggle
                                    label={property.address}
                                    labelAlignment="right"
                                    value={
                                      owner.connectedToProperties.find(
                                        (connected_property) =>
                                          connected_property.id === property.id
                                      ) !== undefined
                                    }
                                    onChange={(
                                      e: React.FormEvent<HTMLInputElement>
                                    ) => {
                                      if (e.currentTarget.checked) {
                                        assignConnectedOwnerToPropertyMutation({
                                          variables: {
                                            propertyId: property.id,
                                            connectedOwnerId: owner.userId!,
                                          },
                                        });
                                      } else {
                                        unassignConnectedOwnerFromPropertyMutation(
                                          {
                                            variables: {
                                              propertyId: property.id,
                                              connectedOwnerId: owner.userId!,
                                            },
                                          }
                                        );
                                      }
                                    }}
                                  />
                                </li>
                              ))}
                          </ul>
                        </div>
                        <div>
                          <ProfileSaveButton
                            onClick={() => {
                              disconnectOwnerMutation({
                                variables: {
                                  id: owner.userId!,
                                },
                              });
                            }}
                          >
                            Aftengja <RemoveCircleIcon />
                          </ProfileSaveButton>
                        </div>
                      </li>
                    ))
                  ) : (
                    <span>
                      Þú ert ekki með neinn tengdan íbúa við aðganginn þinn.
                    </span>
                  )}
                </ConnectedListContainer>

                <ProfileSaveButton
                  buttonType="primary"
                  fluid
                  onClick={() => {
                    setShowConnectModal(true);
                  }}
                >
                  Tengja íbúa við aðganginn minn <AddCircleIcon />
                </ProfileSaveButton>
              </>
            ) : (
              <div>
                <TitleContainer>
                  <TitleLeft>
                    <UserIcon />
                    <CollapsedName>
                      <H1>{name}</H1>
                      <p>kt. {socialNumber}</p>
                    </CollapsedName>
                  </TitleLeft>

                  <TitleRight>
                    <Tooltip tooltipMessage="Opna notendastillingar">
                      <CaretRightIcon
                        onClick={() =>
                          dispatch(setCollapseCard(CARD_KEY, true))
                        }
                      />
                    </Tooltip>
                  </TitleRight>
                </TitleContainer>

                {connectedOwners.length > 0 && (
                  <ConnectedOwnerContainer>
                    <InnerCardSectionTitle>
                      <h2>Tengdir íbúar</h2>
                      <span>
                        Hægt er að gefa tengdum íbúum aðgang að upplýsingum um
                        húsfélög, skjöl og reikninga.
                      </span>
                    </InnerCardSectionTitle>
                    <ConnectedOwnersList>
                      <ul>
                        {connectedOwners.map((owner) => (
                          <li key={owner.userId!}>
                            <PlugDisconnectedIcon />
                            {owner.name} ({owner.socialNumber})
                          </li>
                        ))}
                      </ul>
                    </ConnectedOwnersList>
                  </ConnectedOwnerContainer>
                )}
                {connections.length > 0 && (
                  <ConnectedOwnerContainer>
                    <InnerCardSectionTitle>
                      <h2>Íbúar sem ég tengist</h2>
                      <span>
                        Eftirfarandi íbúar hafa tengt þig við aðganginn sinn.
                      </span>
                    </InnerCardSectionTitle>
                    <ConnectedOwnersList>
                      <ul>
                        {connections.map((owner) => (
                          <li key={owner.userId!}>
                            <PlugDisconnectedIcon />
                            {owner.name} ({owner.socialNumber})
                          </li>
                        ))}
                      </ul>
                    </ConnectedOwnersList>
                    <TipsMesage>
                      <span>
                        Þú getur tengt aðra íbúa við aðganginn þinn í
                        notendastillingum
                      </span>
                    </TipsMesage>
                  </ConnectedOwnerContainer>
                )}
              </div>
            )
          ) : (
            <LoadingCard loadingMessage="Hleð inn upplýsingum um prófílinn þinn" />
          )}
        </InnerCard>
      </Container>
    </>
  );
};
