import * as React from "react";
import Header from "../layout/Header";
import { Page } from "../layout/Page";
import { useParams } from "react-router";
import { LoadingPage } from "../layout/Loading";
import ReactGA from "react-ga";
import { useMutation, useQuery } from "@apollo/client";
import {
  DeleteContactsFromPropertyMutation,
  DeleteContactsFromPropertyMutationVariables,
  GetContactsForPropertyQuery,
  GetContactsForPropertyQueryVariables,
  PropertyContact,
} from "../../../types";
import {
  contactsForProperty,
  deleteContactFromProperty,
} from "../../queries/user";
import {
  BasicButton,
  BasicColoredButton,
  BasicRedButton,
} from "../layout/Buttons";
import {
  Table,
  TableHeader,
  TableTr,
  TableTh,
  TableBody,
} from "../layout/Table";
import { CardContainer, InnerCard } from "../layout/CardCommon";
import { PropertyManageContactModal } from "../modals/PropertyManageContactModal";
import styled from "styled-components";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { ErrorPage } from "../layout/Error";
import { Breadcrumbs, Crumb } from "../layout/BreadCrumbs";

const TableHeading = styled.div`
  padding: 16px;
  border-bottom: 1px solid #dadada;
`;

const ActionButtons = styled.div`
  display: flex;
  button {
    margin-right: 4px;
  }
`;

export const ContactManagePage = () => {
  let { propertyId } = useParams<{ propertyId: string }>();

  const [showManageContact, setShowManageContact] = React.useState<
    PropertyContact
  >();
  const [showNewContact, setShowNewContact] = React.useState<boolean>(false);

  const contactsQuery = useQuery<
    GetContactsForPropertyQuery,
    GetContactsForPropertyQueryVariables
  >(contactsForProperty, {
    variables: {
      propertyId: propertyId,
    },
    fetchPolicy: "cache-and-network",
  });

  const [deleteContactMutation] = useMutation<
    DeleteContactsFromPropertyMutation,
    DeleteContactsFromPropertyMutationVariables
  >(deleteContactFromProperty);

  const contacts = contactsQuery.data?.propertyById.contacts || [];
  const property = contactsQuery.data?.propertyById;

  return (
    <>
      <PropertyManageContactModal
        open={showManageContact !== undefined || showNewContact}
        propertyContact={showManageContact}
        propertyName={property?.address || ""}
        propertyId={propertyId || ""}
        onDone={() => {
          ReactGA.event({
            category: "Management",
            action: "Update or Create Property Contact",
          });
          setShowManageContact(undefined);
          setShowNewContact(false);
          contactsQuery.refetch();
        }}
      />

      <Header />
      {contactsQuery.loading ? (
        <LoadingPage loadingMessage="Sæki tengiliði.." />
      ) : !contactsQuery.error ? (
        <Page>
          <>
            <h1 className="page">Stórnun tengiliða</h1>
            <Breadcrumbs>
              <Crumb>
                <a href="/">Heim</a>
              </Crumb>
              <Crumb>
                <a href="/">Stjórnun tengiliða</a>
              </Crumb>
              {propertyId && (
                <Crumb>
                  <a href={`/property/${propertyId}/management`}>
                    {contactsQuery?.data?.propertyById.address}
                  </a>
                </Crumb>
              )}
            </Breadcrumbs>
            <CardContainer>
              <InnerCard>
                <TableHeading>
                  <h2>Tengiliðir í {property?.address}</h2>Listinn inniheldur
                  alla tengiliðir í húsfélaginu "{property?.address}".
                  <br />
                  Þú sem stjórnandi húsfélagsins getur bætt við, breytt eða
                  tekið út tengiliði.
                  <br />
                  <br />
                  <BasicButton
                    buttonType="primary"
                    onClick={() => {
                      setShowNewContact(true);
                    }}
                  >
                    Bæta við tengilið
                  </BasicButton>
                </TableHeading>

                <Table>
                  <TableHeader>
                    <TableTr>
                      <TableTh>Nafn</TableTh>
                      <TableTh className="hide-mobile">Símanúmer</TableTh>
                      <TableTh className="hide-mobile">Tölvupóstfang</TableTh>
                      <TableTh className="hide-mobile">Hlutverk</TableTh>
                      <TableTh></TableTh>
                    </TableTr>
                  </TableHeader>
                  <TableBody>
                    {contacts.map((contact, index) => (
                      <TableTr key={index}>
                        <TableTh className="hide-mobile">
                          {contact.name}
                        </TableTh>
                        <TableTh className="show-mobile">
                          {contact.name} ({contact.phone}) - {contact.role}
                        </TableTh>
                        <TableTh className="hide-mobile">
                          {contact.phone}
                        </TableTh>
                        <TableTh className="hide-mobile">
                          {contact.email}
                        </TableTh>
                        <TableTh className="hide-mobile">
                          {contact.role}
                        </TableTh>
                        <TableTh>
                          <ActionButtons>
                            <BasicRedButton
                              onClick={() => {
                                confirmAlert({
                                  title: "Ertu viss?",
                                  message: "Þú ert að eyða tengilið.",
                                  buttons: [
                                    {
                                      label: "Já",
                                      onClick: () => {
                                        deleteContactMutation({
                                          variables: {
                                            propertyId: propertyId,
                                            propertyContactId: contact.id,
                                          },
                                        }).then(() => {
                                          contactsQuery.refetch();
                                        });
                                      },
                                    },
                                    {
                                      label: "Nei",
                                      onClick: () => {},
                                    },
                                  ],
                                });
                              }}
                            >
                              Eyða
                            </BasicRedButton>
                            <BasicColoredButton
                              color="#069"
                              onClick={() => {
                                setShowManageContact(contact);
                              }}
                            >
                              Breyta
                            </BasicColoredButton>
                          </ActionButtons>
                        </TableTh>
                      </TableTr>
                    ))}
                  </TableBody>
                </Table>
              </InnerCard>
            </CardContainer>
          </>
        </Page>
      ) : (
        <ErrorPage errorMessage="Villa við að sækja upplýsingar um tengiliði." />
      )}
    </>
  );
};

export default ContactManagePage;
