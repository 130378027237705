import { Reducer } from "redux";
import { LayoutState, LayoutActionTypes } from "./types";

export const initialState: LayoutState = {
  theme: "light",
  collapsed_card: {},
};

const reducer: Reducer<LayoutState> = (state = initialState, action) => {
  switch (action.type) {
    case LayoutActionTypes.SET_THEME: {
      return { ...state, theme: action.payload };
    }
    case LayoutActionTypes.SET_COLLAPSED_CARD: {
      return {
        ...state,
        collapsed_card: {
          ...state.collapsed_card,
          [action.payload.cardKey]: action.payload.collapsed,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as layoutReducer };
