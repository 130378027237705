export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** The `Upload` scalar type represents a file upload. */
  Upload: any,
};


export enum AccessType {
  Basic = 'basic',
  Full = 'full'
}

export type BankAccount = {
   __typename?: 'BankAccount',
  bankAccountNo: Scalars['String'],
  name: Scalars['String'],
  contact: Scalars['String'],
  id: Scalars['String'],
  balanceAtDate: Scalars['String'],
  bankAccountGroup: Scalars['String'],
};

export type BankClaim = {
   __typename?: 'BankClaim',
  no: Scalars['String'],
  postingDate: Scalars['String'],
  bankAccountNo: Scalars['String'],
  description: Scalars['String'],
  amount: Scalars['String'],
  remainingAmount: Scalars['String'],
  open: Scalars['Boolean'],
  entryType: Scalars['String'],
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type ConnectedOwner = {
   __typename?: 'ConnectedOwner',
  userId?: Maybe<Scalars['ID']>,
  socialNumber: Scalars['String'],
  name: Scalars['String'],
  email?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  connectedToProperties: Array<ConnectedProperty>,
};

export type ConnectedProperty = {
   __typename?: 'ConnectedProperty',
  id: Scalars['ID'],
  address: Scalars['String'],
  registrationId?: Maybe<Scalars['String']>,
  externalId?: Maybe<Scalars['String']>,
};

export type CreateAndAssignResult = {
   __typename?: 'CreateAndAssignResult',
  success: Scalars['Boolean'],
  ownerId: Scalars['String'],
};

export type DateRangeFilter = {
  fromDate: Scalars['String'],
  toDate: Scalars['String'],
};

export type Document = {
   __typename?: 'Document',
  id: Scalars['String'],
  name: Scalars['String'],
  type: Scalars['String'],
  mimetype: Scalars['String'],
  description: Scalars['String'],
  createdDate: Scalars['String'],
  modifiedDate: Scalars['String'],
  displayDate: Scalars['String'],
};

export type DocumentFilter = {
  fromDate?: Maybe<Scalars['String']>,
  toDate?: Maybe<Scalars['String']>,
  propertyId?: Maybe<Scalars['String']>,
};

export type Invoice = {
   __typename?: 'Invoice',
  invoiceNo: Scalars['String'],
  socialNumber: Scalars['String'],
  amount: Scalars['String'],
  dueDate: Scalars['String'],
  remainingAmount: Scalars['String'],
};

export type InvoiceApproval = {
   __typename?: 'InvoiceApproval',
  approvalNo: Scalars['String'],
  creditInvoice: Scalars['Boolean'],
  invoiceToNo: Scalars['String'],
  invoiceToName: Scalars['String'],
  vendorNo: Scalars['String'],
  vendorName: Scalars['String'],
  date: Scalars['String'],
  documentNo: Scalars['String'],
  description: Scalars['String'],
  reference: Scalars['String'],
  deliveryDate: Scalars['String'],
  totalAmount: Scalars['String'],
  totalAmountWithVat: Scalars['String'],
  lineTotalAmountWithVat: Scalars['String'],
  approvalStatus: Scalars['String'],
  approvalMessage: Scalars['String'],
  approved: Scalars['Boolean'],
};

export type InvoiceDetail = {
   __typename?: 'InvoiceDetail',
  totalAmount: Scalars['String'],
  description1?: Maybe<Scalars['String']>,
  description2?: Maybe<Scalars['String']>,
  lines: Array<InvoiceLine>,
};

export type InvoiceLine = {
   __typename?: 'InvoiceLine',
  amount: Scalars['String'],
  description: Scalars['String'],
};

export type InvoiceWithDetails = {
   __typename?: 'InvoiceWithDetails',
  invoiceNo: Scalars['String'],
  socialNumber: Scalars['String'],
  amount: Scalars['String'],
  dueDate: Scalars['String'],
  remainingAmount: Scalars['String'],
  details?: Maybe<InvoiceDetail>,
};

export type Mutation = {
   __typename?: 'Mutation',
  /** Update logged in owner */
  updateOwner: Scalars['Boolean'],
  /** Connect another owner to the logged in owner */
  connectOwner: Scalars['Boolean'],
  /** Disconnect an already connected owner from the logged in owner */
  disconnectConnectedOwner: Scalars['Boolean'],
  /** Create and connect and owner (Use if owner is not already registered in the Eignarekstur's systems.) */
  createAndConnectOwner: CreateAndAssignResult,
  /** Approve an invoice (Only works if you have full access to the property) */
  approveInvoice: Scalars['Boolean'],
  /** Send and request access notification to Eignarekstur. */
  sendRequestAccessNotification: Scalars['Boolean'],
  /** Assign an owner to a property (Only work if logged in user has full access to the property) */
  assignOwnerToProperty: Scalars['Boolean'],
  /** Assign an connected owner to a property. */
  assignConnectedOwnerToProperty: Scalars['Boolean'],
  /** Unassign an owner to a property (Only work if logged in user has full access to the property) */
  unassignOwnerFromProperty: Scalars['Boolean'],
  /** Unassign a connected owner from a property. */
  unassignConnectedOwnerFromProperty: Scalars['Boolean'],
  /** 
 * Create and assign an owner to a property (Only work if logged in user has full access to the property)
   * Should be used when owner being assigned does not already exists in the Eignarekstur systems.
 */
  createAndAssignOwnerToProperty: CreateAndAssignResult,
  /** Create a contact for a property (Only work if logged in user has full access to the property) */
  createPropertyContact: PropertyContact,
  /** Update a contact for a property (Only work if logged in user has full access to the property) */
  updatePropertyContact: PropertyContact,
  /** Delete a contact for a property (Only work if logged in user has full access to the property) */
  deletePropertyContact: Scalars['Boolean'],
};


export type MutationUpdateOwnerArgs = {
  owner: OwnerUpdateInput
};


export type MutationConnectOwnerArgs = {
  ownerId: Scalars['String'],
  reason?: Maybe<Scalars['String']>
};


export type MutationDisconnectConnectedOwnerArgs = {
  ownerId: Scalars['String']
};


export type MutationCreateAndConnectOwnerArgs = {
  owner: OwnerAssignmentInput,
  reason?: Maybe<Scalars['String']>
};


export type MutationApproveInvoiceArgs = {
  propertyName: Scalars['String'],
  socialNumber: Scalars['String'],
  approve: Scalars['Boolean'],
  rejectReason?: Maybe<Scalars['String']>,
  invoiceNo: Scalars['String']
};


export type MutationSendRequestAccessNotificationArgs = {
  requestInput: RequestAccessNotificationInput
};


export type MutationAssignOwnerToPropertyArgs = {
  propertyId: Scalars['String'],
  ownerId: Scalars['String']
};


export type MutationAssignConnectedOwnerToPropertyArgs = {
  propertyId?: Maybe<Scalars['String']>,
  connectedOwnerId: Scalars['String']
};


export type MutationUnassignOwnerFromPropertyArgs = {
  propertyId: Scalars['String'],
  ownerId: Scalars['String']
};


export type MutationUnassignConnectedOwnerFromPropertyArgs = {
  propertyId?: Maybe<Scalars['String']>,
  connectedOwnerId: Scalars['String']
};


export type MutationCreateAndAssignOwnerToPropertyArgs = {
  owner: OwnerAssignmentInput,
  propertyId: Scalars['String']
};


export type MutationCreatePropertyContactArgs = {
  propertyContact: PropertyContactInput
};


export type MutationUpdatePropertyContactArgs = {
  propertyContact: PropertyContactInput,
  propertyContactId: Scalars['String']
};


export type MutationDeletePropertyContactArgs = {
  propertyId: Scalars['String'],
  propertyContactId: Scalars['String']
};

export type Owner = {
   __typename?: 'Owner',
  userId?: Maybe<Scalars['ID']>,
  socialNumber: Scalars['String'],
  name: Scalars['String'],
  email?: Maybe<Scalars['String']>,
  userType: UserType,
  phone?: Maybe<Scalars['String']>,
  session: Session,
};

export type OwnerAssignmentInput = {
  socialNumber: Scalars['String'],
  name: Scalars['String'],
  email?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
};

export type OwnerCard = {
   __typename?: 'OwnerCard',
  id?: Maybe<Scalars['ID']>,
  socialNumber: Scalars['String'],
  name: Scalars['String'],
};

export type OwnerConnection = {
   __typename?: 'OwnerConnection',
  userId?: Maybe<Scalars['ID']>,
  socialNumber: Scalars['String'],
  name: Scalars['String'],
  email?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  properties: Array<OwnerConnectionProperty>,
};

/** Properties for owner connection */
export type OwnerConnectionProperty = {
   __typename?: 'OwnerConnectionProperty',
  id: Scalars['ID'],
  address: Scalars['String'],
  registrationId?: Maybe<Scalars['String']>,
  externalId?: Maybe<Scalars['String']>,
  socialNumberConnection: Scalars['String'],
  invoices: Array<Invoice>,
};


/** Properties for owner connection */
export type OwnerConnectionPropertyInvoicesArgs = {
  take?: Maybe<Scalars['Int']>
};

export type OwnerUpdateInput = {
  email: Scalars['String'],
  phone?: Maybe<Scalars['String']>,
};

export type Property = {
   __typename?: 'Property',
  id: Scalars['ID'],
  address: Scalars['String'],
  registrationId?: Maybe<Scalars['String']>,
  externalId?: Maybe<Scalars['String']>,
  access: AccessType,
  invoices: Array<Invoice>,
  contacts: Array<PropertyContact>,
  bankAccounts?: Maybe<Array<BankAccount>>,
  bankClaims?: Maybe<Array<BankClaim>>,
  invoiceApprovals?: Maybe<Array<InvoiceApproval>>,
  documentsCount: Scalars['Int'],
  ownersCount: Scalars['Int'],
  propertySupportRequests?: Maybe<Array<PropertySupportRequest>>,
  informations: Array<PropertyInformation>,
};


export type PropertyBankClaimsArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  dateFrom?: Maybe<Scalars['String']>,
  dateTo?: Maybe<Scalars['String']>
};

export type PropertyContact = {
   __typename?: 'PropertyContact',
  id: Scalars['ID'],
  name: Scalars['String'],
  role?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  propertyId: Scalars['String'],
};

export type PropertyContactInput = {
  name: Scalars['String'],
  role: Scalars['String'],
  phone?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  propertyId: Scalars['String'],
};

export type PropertyInformation = {
   __typename?: 'PropertyInformation',
  key: Scalars['String'],
  title: Scalars['String'],
  value: Scalars['String'],
  createdAt: Scalars['String'],
  modifiedAt: Scalars['String'],
};

export type PropertySupportRequest = {
   __typename?: 'PropertySupportRequest',
  id: Scalars['String'],
  summary: Scalars['String'],
  description: Scalars['String'],
  assignee: Scalars['String'],
  reporter: Scalars['String'],
  date: Scalars['String'],
  finished: Scalars['Boolean'],
};

export type Query = {
   __typename?: 'Query',
  /** Get Information about the logged in user */
  me: Owner,
  /** Get owners connected to the logged in user. */
  connectedOwners?: Maybe<Array<ConnectedOwner>>,
  /** Get connections to other owners for the logged in user. */
  ownerConnections?: Maybe<Array<OwnerConnection>>,
  /** Get Owner By Social Number (Only Basic Information) */
  getOwnerCardBySocialNumber: OwnerCard,
  /** Get Owners by Property ID (Only Basic Information) */
  getOwnerCardsByProperty: Array<OwnerCard>,
  /** Get Invoices by Property name */
  invoicesByPropertyName: Array<Invoice>,
  /** Get Invoice by Property and invoice number. */
  invoiceByPropertyNameAndInvoiceNo?: Maybe<InvoiceWithDetails>,
  /** Get Invoice approvals by property name. */
  invoiceApprovalsByPropertyName: Array<InvoiceApproval>,
  /** Get all documents */
  documents: Array<Document>,
  /** Get all documents with Date Filter */
  documentsByDateFilter: Array<Document>,
  /** Get all documents with filtering */
  documentsV2: Array<Document>,
  /** Get all properties that the logged in owner has access to. */
  properties: Array<Property>,
  /** Get Property by ID, only works if you have access to the property */
  propertyById: Property,
};


export type QueryGetOwnerCardBySocialNumberArgs = {
  socialNumber: Scalars['String']
};


export type QueryGetOwnerCardsByPropertyArgs = {
  propertyId: Scalars['String']
};


export type QueryInvoicesByPropertyNameArgs = {
  propertyName: Scalars['String'],
  take: Scalars['Int']
};


export type QueryInvoiceByPropertyNameAndInvoiceNoArgs = {
  propertyName: Scalars['String'],
  invoiceNo: Scalars['String']
};


export type QueryInvoiceApprovalsByPropertyNameArgs = {
  propertyName: Scalars['String']
};


export type QueryDocumentsByDateFilterArgs = {
  filter: DateRangeFilter,
  limit: Scalars['Int'],
  page: Scalars['Int']
};


export type QueryDocumentsV2Args = {
  filter?: Maybe<DocumentFilter>,
  limit: Scalars['Int'],
  page: Scalars['Int']
};


export type QueryPropertyByIdArgs = {
  id: Scalars['String']
};

export type RequestAccessNotificationInput = {
  propertyName: Scalars['String'],
  ownerName: Scalars['String'],
  phoneNumber?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
};

export type Session = {
   __typename?: 'Session',
  expiration: Scalars['String'],
};


export enum UserType {
  Basic = 'BASIC',
  Customer = 'CUSTOMER'
}

export type GetProfileInfoQueryVariables = {};


export type GetProfileInfoQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Owner' }
    & Pick<Owner, 'userId' | 'socialNumber' | 'name' | 'email' | 'phone' | 'userType'>
    & { session: (
      { __typename?: 'Session' }
      & Pick<Session, 'expiration'>
    ) }
  ), properties: Array<(
    { __typename?: 'Property' }
    & Pick<Property, 'address' | 'id'>
  )>, connectedOwners: Maybe<Array<(
    { __typename?: 'ConnectedOwner' }
    & Pick<ConnectedOwner, 'userId' | 'socialNumber' | 'name' | 'email' | 'phone'>
    & { connectedToProperties: Array<(
      { __typename?: 'ConnectedProperty' }
      & Pick<ConnectedProperty, 'id' | 'address' | 'externalId'>
    )> }
  )>>, ownerConnections: Maybe<Array<(
    { __typename?: 'OwnerConnection' }
    & Pick<OwnerConnection, 'userId' | 'socialNumber' | 'name' | 'email' | 'phone'>
    & { properties: Array<(
      { __typename?: 'OwnerConnectionProperty' }
      & Pick<OwnerConnectionProperty, 'id' | 'address' | 'externalId'>
    )> }
  )>> }
);

export type GetDocumentsByRangeQueryVariables = {
  fromDate: Scalars['String'],
  toDate: Scalars['String'],
  limit: Scalars['Int'],
  page: Scalars['Int']
};


export type GetDocumentsByRangeQuery = (
  { __typename?: 'Query' }
  & { documentsByDateFilter: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'name' | 'type' | 'mimetype' | 'description' | 'createdDate' | 'modifiedDate' | 'displayDate'>
  )> }
);

export type GetDocumentsV2QueryVariables = {
  fromDate?: Maybe<Scalars['String']>,
  toDate?: Maybe<Scalars['String']>,
  limit: Scalars['Int'],
  page: Scalars['Int'],
  propertyId?: Maybe<Scalars['String']>
};


export type GetDocumentsV2Query = (
  { __typename?: 'Query' }
  & { documentsV2: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'name' | 'type' | 'mimetype' | 'description' | 'createdDate' | 'modifiedDate' | 'displayDate'>
  )> }
);

export type GetInvoiceLinesQueryVariables = {
  propertyName: Scalars['String'],
  invoiceNo: Scalars['String']
};


export type GetInvoiceLinesQuery = (
  { __typename?: 'Query' }
  & { invoiceByPropertyNameAndInvoiceNo: Maybe<(
    { __typename?: 'InvoiceWithDetails' }
    & Pick<InvoiceWithDetails, 'invoiceNo' | 'amount' | 'dueDate' | 'remainingAmount'>
    & { details: Maybe<(
      { __typename?: 'InvoiceDetail' }
      & Pick<InvoiceDetail, 'description1' | 'description2' | 'totalAmount'>
      & { lines: Array<(
        { __typename?: 'InvoiceLine' }
        & Pick<InvoiceLine, 'amount' | 'description'>
      )> }
    )> }
  )> }
);

export type ApproveInvoiceLineMutationVariables = {
  propertyName: Scalars['String'],
  socialNumber: Scalars['String'],
  approve: Scalars['Boolean'],
  invoiceNo: Scalars['String'],
  rejectReason?: Maybe<Scalars['String']>
};


export type ApproveInvoiceLineMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'approveInvoice'>
);

export type GetAllowedPropertiesQueryVariables = {};


export type GetAllowedPropertiesQuery = (
  { __typename?: 'Query' }
  & { properties: Array<(
    { __typename?: 'Property' }
    & Pick<Property, 'address' | 'id'>
  )>, connectedOwners: Maybe<Array<(
    { __typename?: 'ConnectedOwner' }
    & Pick<ConnectedOwner, 'userId' | 'socialNumber' | 'name' | 'email' | 'phone'>
    & { connectedToProperties: Array<(
      { __typename?: 'ConnectedProperty' }
      & Pick<ConnectedProperty, 'id' | 'address' | 'externalId'>
    )> }
  )>> }
);

export type InvoicesByPropertyNameQueryVariables = {
  propertyName: Scalars['String'],
  take: Scalars['Int']
};


export type InvoicesByPropertyNameQuery = (
  { __typename?: 'Query' }
  & { invoicesByPropertyName: Array<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'invoiceNo' | 'socialNumber' | 'amount' | 'dueDate' | 'remainingAmount'>
  )> }
);

export type GetPropertiesQueryVariables = {};


export type GetPropertiesQuery = (
  { __typename?: 'Query' }
  & { properties: Array<(
    { __typename?: 'Property' }
    & Pick<Property, 'id' | 'address' | 'externalId' | 'registrationId' | 'access'>
  )> }
);

export type GetPropertiesForDashboardQueryVariables = {};


export type GetPropertiesForDashboardQuery = (
  { __typename?: 'Query' }
  & { documentsV2: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'name' | 'type' | 'mimetype' | 'description' | 'createdDate' | 'modifiedDate' | 'displayDate'>
  )>, properties: Array<(
    { __typename?: 'Property' }
    & Pick<Property, 'id' | 'address' | 'externalId' | 'registrationId' | 'access' | 'documentsCount' | 'ownersCount'>
    & { contacts: Array<(
      { __typename?: 'PropertyContact' }
      & Pick<PropertyContact, 'id' | 'name' | 'phone' | 'email' | 'role' | 'propertyId'>
    )>, propertySupportRequests: Maybe<Array<(
      { __typename?: 'PropertySupportRequest' }
      & Pick<PropertySupportRequest, 'id' | 'summary' | 'assignee' | 'reporter' | 'date' | 'description' | 'finished'>
    )>>, informations: Array<(
      { __typename?: 'PropertyInformation' }
      & Pick<PropertyInformation, 'key' | 'title' | 'value' | 'createdAt' | 'modifiedAt'>
    )> }
  )>, ownerConnections: Maybe<Array<(
    { __typename?: 'OwnerConnection' }
    & { properties: Array<(
      { __typename?: 'OwnerConnectionProperty' }
      & Pick<OwnerConnectionProperty, 'id' | 'address' | 'externalId'>
    )> }
  )>> }
);

export type GetPropertiesInvoicesForDashboardQueryVariables = {};


export type GetPropertiesInvoicesForDashboardQuery = (
  { __typename?: 'Query' }
  & { properties: Array<(
    { __typename?: 'Property' }
    & Pick<Property, 'id' | 'address'>
    & { invoiceApprovals: Maybe<Array<(
      { __typename?: 'InvoiceApproval' }
      & Pick<InvoiceApproval, 'approvalNo' | 'approvalStatus'>
    )>>, invoices: Array<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'invoiceNo' | 'socialNumber' | 'amount' | 'dueDate' | 'remainingAmount'>
    )> }
  )>, ownerConnections: Maybe<Array<(
    { __typename?: 'OwnerConnection' }
    & { properties: Array<(
      { __typename?: 'OwnerConnectionProperty' }
      & Pick<OwnerConnectionProperty, 'id' | 'address' | 'externalId'>
      & { invoices: Array<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'invoiceNo' | 'socialNumber' | 'amount' | 'dueDate' | 'remainingAmount'>
      )> }
    )> }
  )>> }
);

export type GetPropertyFinancialsDetailQueryVariables = {
  propertyId: Scalars['String'],
  bankClaimsTake?: Maybe<Scalars['Int']>,
  bankClaimsSkip?: Maybe<Scalars['Int']>,
  bankClaimsDateFrom?: Maybe<Scalars['String']>,
  bankClaimsDateTo?: Maybe<Scalars['String']>
};


export type GetPropertyFinancialsDetailQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Owner' }
    & Pick<Owner, 'userId' | 'socialNumber'>
  ), propertyById: (
    { __typename?: 'Property' }
    & Pick<Property, 'id' | 'address' | 'externalId' | 'registrationId' | 'access'>
    & { bankAccounts: Maybe<Array<(
      { __typename?: 'BankAccount' }
      & Pick<BankAccount, 'bankAccountNo' | 'name' | 'contact' | 'id' | 'balanceAtDate' | 'bankAccountGroup'>
    )>>, bankClaims: Maybe<Array<(
      { __typename?: 'BankClaim' }
      & Pick<BankClaim, 'no' | 'postingDate' | 'bankAccountNo' | 'description' | 'amount' | 'remainingAmount' | 'open' | 'entryType'>
    )>>, invoiceApprovals: Maybe<Array<(
      { __typename?: 'InvoiceApproval' }
      & Pick<InvoiceApproval, 'approvalNo' | 'creditInvoice' | 'invoiceToNo' | 'invoiceToName' | 'vendorNo' | 'vendorName' | 'date' | 'documentNo' | 'description' | 'reference' | 'deliveryDate' | 'totalAmount' | 'totalAmountWithVat' | 'lineTotalAmountWithVat' | 'approvalStatus' | 'approvalMessage' | 'approved'>
    )>> }
  ) }
);

export type GetPropertyDetailQueryVariables = {
  propertyId: Scalars['String']
};


export type GetPropertyDetailQuery = (
  { __typename?: 'Query' }
  & { propertyById: (
    { __typename?: 'Property' }
    & Pick<Property, 'id' | 'address' | 'externalId' | 'registrationId' | 'access'>
    & { contacts: Array<(
      { __typename?: 'PropertyContact' }
      & Pick<PropertyContact, 'id' | 'name' | 'role' | 'phone' | 'email' | 'propertyId'>
    )> }
  ) }
);

export type AssignOwnerToPropertyMutationVariables = {
  propertyId: Scalars['String'],
  ownerId: Scalars['String']
};


export type AssignOwnerToPropertyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignOwnerToProperty'>
);

export type AssignConnectedOwnerToPropertyMutationVariables = {
  propertyId: Scalars['String'],
  connectedOwnerId: Scalars['String']
};


export type AssignConnectedOwnerToPropertyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignConnectedOwnerToProperty'>
);

export type UnassignOwnerFromPropertyMutationVariables = {
  propertyId: Scalars['String'],
  ownerId: Scalars['String']
};


export type UnassignOwnerFromPropertyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unassignOwnerFromProperty'>
);

export type UnassignConnectedOwnerFromPropertyMutationVariables = {
  propertyId: Scalars['String'],
  connectedOwnerId: Scalars['String']
};


export type UnassignConnectedOwnerFromPropertyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unassignConnectedOwnerFromProperty'>
);

export type CreateAndAssignOwnerToPropertyMutationVariables = {
  owner: OwnerAssignmentInput,
  propertyId: Scalars['String']
};


export type CreateAndAssignOwnerToPropertyMutation = (
  { __typename?: 'Mutation' }
  & { createAndAssignOwnerToProperty: (
    { __typename?: 'CreateAndAssignResult' }
    & Pick<CreateAndAssignResult, 'success' | 'ownerId'>
  ) }
);

export type GetLoggedInUserQueryVariables = {};


export type GetLoggedInUserQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Owner' }
    & Pick<Owner, 'userId' | 'socialNumber' | 'name' | 'email' | 'phone' | 'userType'>
    & { session: (
      { __typename?: 'Session' }
      & Pick<Session, 'expiration'>
    ) }
  ) }
);

export type UpdateOwnerMutationVariables = {
  owner: OwnerUpdateInput
};


export type UpdateOwnerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOwner'>
);

export type SendRequestNotificationMutationVariables = {
  input: RequestAccessNotificationInput
};


export type SendRequestNotificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendRequestAccessNotification'>
);

export type GetOwnerCardsByPropertyQueryVariables = {
  propertyId: Scalars['String']
};


export type GetOwnerCardsByPropertyQuery = (
  { __typename?: 'Query' }
  & { getOwnerCardsByProperty: Array<(
    { __typename?: 'OwnerCard' }
    & Pick<OwnerCard, 'id' | 'name' | 'socialNumber'>
  )>, propertyById: (
    { __typename?: 'Property' }
    & Pick<Property, 'address' | 'access'>
  ) }
);

export type GetContactsForPropertyQueryVariables = {
  propertyId: Scalars['String']
};


export type GetContactsForPropertyQuery = (
  { __typename?: 'Query' }
  & { propertyById: (
    { __typename?: 'Property' }
    & Pick<Property, 'address'>
    & { contacts: Array<(
      { __typename?: 'PropertyContact' }
      & Pick<PropertyContact, 'id' | 'name' | 'role' | 'email' | 'phone' | 'propertyId'>
    )> }
  ) }
);

export type DeleteContactsFromPropertyMutationVariables = {
  propertyId: Scalars['String'],
  propertyContactId: Scalars['String']
};


export type DeleteContactsFromPropertyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePropertyContact'>
);

export type UpdateContactsForPropertyMutationVariables = {
  propertyContactId: Scalars['String'],
  propertyContact: PropertyContactInput
};


export type UpdateContactsForPropertyMutation = (
  { __typename?: 'Mutation' }
  & { updatePropertyContact: (
    { __typename?: 'PropertyContact' }
    & Pick<PropertyContact, 'id' | 'name' | 'role' | 'phone' | 'email' | 'propertyId'>
  ) }
);

export type CreateContactsForPropertyMutationVariables = {
  propertyContact: PropertyContactInput
};


export type CreateContactsForPropertyMutation = (
  { __typename?: 'Mutation' }
  & { createPropertyContact: (
    { __typename?: 'PropertyContact' }
    & Pick<PropertyContact, 'id' | 'name' | 'role' | 'phone' | 'email' | 'propertyId'>
  ) }
);

export type GetOwnerCardBySocialNumberQueryVariables = {
  socialNumber: Scalars['String']
};


export type GetOwnerCardBySocialNumberQuery = (
  { __typename?: 'Query' }
  & { getOwnerCardBySocialNumber: (
    { __typename?: 'OwnerCard' }
    & Pick<OwnerCard, 'id' | 'name' | 'socialNumber'>
  ) }
);

export type DisconnectOwnerMutationVariables = {
  id: Scalars['String']
};


export type DisconnectOwnerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'disconnectConnectedOwner'>
);

export type ConnectOwnerMutationVariables = {
  id: Scalars['String'],
  reason?: Maybe<Scalars['String']>
};


export type ConnectOwnerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'connectOwner'>
);

export type CreateAndConnectOwnerMutationVariables = {
  input: OwnerAssignmentInput,
  reason?: Maybe<Scalars['String']>
};


export type CreateAndConnectOwnerMutation = (
  { __typename?: 'Mutation' }
  & { createAndConnectOwner: (
    { __typename?: 'CreateAndAssignResult' }
    & Pick<CreateAndAssignResult, 'success' | 'ownerId'>
  ) }
);
