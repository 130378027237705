import { gql } from "@apollo/client";

export const getDocumentsByRange = gql`
  query GetDocumentsByRange(
    $fromDate: String!
    $toDate: String!
    $limit: Int!
    $page: Int!
  ) {
    documentsByDateFilter(
      filter: { fromDate: $fromDate, toDate: $toDate }
      limit: $limit
      page: $page
    ) {
      id
      name
      type
      mimetype
      description
      createdDate
      modifiedDate
      displayDate
    }
  }
`;

export const getGetDocumentsV2 = gql`
  query GetDocumentsV2(
    $fromDate: String
    $toDate: String
    $limit: Int!
    $page: Int!
    $propertyId: String
  ) {
    documentsV2(
      limit: $limit
      page: $page
      filter: { fromDate: $fromDate, toDate: $toDate, propertyId: $propertyId }
    ) {
      id
      name
      type
      mimetype
      description
      createdDate
      modifiedDate
      displayDate
    }
  }
`;
