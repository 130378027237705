export type ThemeColors = "light" | "dark";

export enum LayoutActionTypes {
  SET_THEME = "@@layout/SET_THEME",
  SET_COLLAPSED_CARD = "@@layout/SET_COLLAPSED_CARD",
}

export interface LayoutState {
  readonly theme: ThemeColors;
  readonly collapsed_card: { [key: string]: boolean };
}
